<template>
  <div v-loading="loading">
    <form class="mt-4">
      <div class="form-group">
        <label for="Account">
          <!-- {{ $t("Set.035@account_no./iban") }} -->
          Account No./IBAN
        </label>
        <input
          type="text"
          class="form-control mb-0"
          id="Account"
          v-model="bankList.account"
        />
      </div>
      <!-- First Name -->
      <div class="form-group">
        <label for="BankName">{{ $t("Set.dc0@bank_name") }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="BankName"
          v-model="bankList.bank_name"
        />
      </div>
      <!--Country -->
      <div class="form-group">
        <label for="Country">{{ $t("Set.72f@country") }}</label>
        <select class="form-control" v-model="bankList.nation_id">
          <option v-for="v of nationalList" :key="v.id" :value="v.id">
            {{ v.name }}
          </option>
        </select>
      </div>
      <!-- ID Card NO. -->
      <div class="form-group">
        <label for="ClearingCode">{{ $t("Set.f7b@swift_clearing_cod") }}</label>
        <div class="row m-0">
          <select class="form-control col-4" v-model="bankList.bank_type">
            <option v-for="(v, i) in swiftList" :key="i" :value="v.id">
              {{ v.name }}
            </option>
          </select>
          <!-- <input type="text" maxlength="11" placeholder="SWIFT Code" v-model="bankList.bank_type" @input="switCode"  class="form-control col-5"> -->
          <input
            type="text"
            @input="clearCode"
            maxlength="11"
            class="form-control offset-1 col-7"
            id="ClearingCode"
            v-model="bankList.bank_clearing_code"
          />
        </div>
      </div>
      <!-- Address -->
      <div class="form-group">
        <label for="Address">{{ $t("Set.deb@address") }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="Address"
          v-model="bankList.address"
        />
      </div>
      <!-- Photo of ID card -->
      <div class="form-group">
        <label>{{ $t("Set.9d6@monthly_statement") }}</label>
        <div>
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :http-request="uploadStatement"
          >
            <img class="w-100" v-if="imageUrl" :src="imageUrl" alt="" />
            <img
              class="w-100"
              v-else
              src="../../assets/images/settings/camera.png"
              alt=""
            />
          </el-upload>
        </div>
      </div>
    </form>
    <div class="mt-5 mb-3">
      <span v-show="bankList.id">
        <button
          v-if="bankList.status == '0'"
          type="submit"
          class="btn btn-primary mr-2"
          @click="$emit('cancelApply', bankList.id)"
        >
          Cancel application
        </button>
        <button
          v-else-if="bankList.status == '1' || bankList.status == '2'"
          type="submit"
          class="btn btn-primary mr-2"
          @click="judge()"
        >
          Amend
        </button>
      </span>
      <button
        v-show="!bankList.id"
        type="submit"
        class="btn btn-primary mr-2"
        @click="judge()"
      >
        {{ $t("Set.44e@submit") }}
      </button>
      <button type="submit" class="btn btn-danger" @click="back(), clearBank()">
        {{ $t("Set.be6@back") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    // this.getAddress(2);
  },
  data() {
    return {
      isEdit: false,
      bankList: {
        account: "",
        bank_name: "",
        nation_id: "",
        bank_clearing_code: "",
        bank_type: "",
        address: "",
        monthly_statement: "",
      },
      swiftList: [
        { id: "1", name: "SWIFT Code" },
        { id: "2", name: "Clearing Code" },
      ],
      imageUrl: "",
      loading:false
    };
  },
  props: ["nationalList"],
  methods: {
    // SWIFT Code由8个或11个字符组成。

    // Clearing code是6位
    clearCode() {
      this.bankList.bank_clearing_code =
        this.bankList.bank_clearing_code.replace(/[\W]/g, "");
    },
    switCode() {
      this.bankList.bank_type = this.bankList.bank_type.replace(/[\W]/g, "");
    },
    // 返回上一步
    back() {
      this.$emit("previous");
    },
    clearBank() {
      this.bankList.account = "";
      this.bankList.bank_name = "";
      this.bankList.nation_id = "";
      this.bankList.bank_clearing_code = "";
      this.bankList.bank_type = "";
      this.bankList.address = "";
      this.bankList.monthly_statement = "";
      this.imageUrl = "";
      this.bankList = {};
    },
    // ///////// ///bank //////////
    // 身份证正面
    uploadStatement(params) {
      this.loading=true
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data.toString());
          this.bankList.monthly_statement = res.data.toString();
          this.imageUrl = res.data.toString();
        }
        this.loading=false
      });
    },
    // 增加银行卡信息
    addBank() {
      const data = JSON.parse(JSON.stringify(this.bankList));
      // console.log(data);
      this.$http.addBank(data).then((res) => {
        if (res.status == 200) {
          // console.log(res);
          this.back();
          this.clearBank();
        }
      });
    },
    // 获取单条银行卡信息
    getBankFirst(id) {
      this.bankList = {};
      this.isEdit = true;
      this.$http.getBankFirst({ bank_id: id }).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.bankList = res.data;
            this.imageUrl = res.data.monthly_statement;
          }

          // console.log(this.bankList);
        }
      });
    },
    judge() {
      if (this.bankList.id) {
        this.editBank();
      } else {
        this.addBank();
      }
    },
    // 编辑银行卡信息
    editBank() {
      const data = JSON.parse(JSON.stringify(this.bankList));
      this.$http
        .editBank({
          bank_id: this.bankList.id,
          account: data.account,
          bank_name: data.bank_name,
          bank_type: data.bank_type,
          nation_id: data.nation_id,
          bank_clearing_code: data.bank_clearing_code,
          address: data.address,
          monthly_statement: data.monthly_statement,
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res);
            this.back();
            this.clearBank();
          }
        });
    },
    // 信息填写取消
    reset() {
      this.bankList.account = "";
      this.bankList.bank_name = "";
      this.bankList.nation_id = "";
      this.bankList.bank_clearing_code = "";
      this.bankList.address = "";
      this.bankList.monthly_statement = "";
      this.bankList.bank_type = "";
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-uploader {
  width: 90px;
  height: 90px;
  overflow: hidden;
  img {
    width: 90px;
    height: 90px;
  }
}
</style>
