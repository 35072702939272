<template>
  <div class="AccountSecurity row">
    <div class="col-md-3 col-3">
      <!-- 垂直 tab 选项卡  -->
      <tab-nav :pills="true" id="tab" class="nav nav-pills basic-info-items list-inline d-block p-0 m-0">
        <!-- Password -->
        <tab-nav-items :active="false||$route.query.id!=1" id="pills-contact-info" href="#contact-info-password"
          ariaControls="pills-contact-info" role="tab" :ariaSelected="false||$route.query.id!=1"
          :title="$t('Set.7be@password')" class="tab-nav-items-text-color" />
        <!-- Payment password -->
        <tab-nav-items :active="false||$route.query.id==1" id="pills-family-info" href="#family-info-payment-password"
          ariaControls="pills-family-info" role="tab" :ariaSelected="false||$route.query.id==1"
          :title="$t('Set.4fd@payment_password')" class="tab-nav-items-text-color" @click.native="getUserInfo" />
        <!--  2FA -->
        <tab-nav-items :active="false" id="pills-family-info" href="#family-info-google-2fa"
          ariaControls="pills-family-info" role="tab" :ariaSelected="true" title="Google Authenticator"
          class="tab-nav-items-text-color" />
        <!-- Sign-in Logs -->
        <tab-nav-items :active="false" id="pills-work-info" href="#work-info-sign-in-logs"
          ariaControls="pills-work-info" role="tab" :ariaSelected="true" title="Sign-in Logs"
          class="tab-nav-items-text-color" />
      </tab-nav>
    </div>
    <div class="col-md-9 col-9">
      <div class="tab-content">
        <!-- Password -->
        <tab-content-item :active="false||$route.query.id!=1" id="contact-info-password"
          aria-labelled-by="pills-contact-info">
          <h4>{{ $t("Set.7be@password") }}</h4>
          <hr />
          <form class="mt-4">
            <!-- Current Password: -->
            <div class="form-group passwordInt">
              <label for="exampleInputCurrentPassword">{{ $t("Set.b23@current_password") }}:</label>
              <input type="password" class="form-control mb-0" ref="password" id="exampleInputCurrentPassword"
                placeholder="" v-model="passwordForm.current_password" autocomplete="off" />
              <i ref="eye" class="ri-eye-line" @click="passIntType"></i>
            </div>
            <!-- New Password: -->
            <div class="form-group passwordInt">
              <label for="exampleInputNewPassword">{{ $t("Set.197@new_password") }}:</label>
              <input type="password" ref="password1" class="form-control mb-0" id="exampleInputNewPassword"
                placeholder="Password length must be at least 8 characters." v-model="passwordForm.password"
                autocomplete="off" />
              <i ref="eye1" class="ri-eye-line" @click="passNewInt"></i>
            </div>
            <!-- Verify Password: -->
            <div class="form-group passwordInt">
              <label for="exampleInputVerifyPassword">
                <!-- {{ $t("Set.d19@verify_password") }}: -->
                Verify Password:
              </label>
              <input type="password" ref="password2" class="form-control mb-0" id="exampleInputVerifyPassword"
                v-model="passwordForm.password_confirmation" autocomplete="off" />
              <i ref="eye2" class="ri-eye-line" @click="changeType"></i>
            </div>
            <div class="mt-5">
              <button class="btn btn-primary mr-2" @click="updatePassword">
                {{ $t("Set.ca8@submit") }}
              </button>
              <button type="reset" @click="reset" class="btn btn-danger">
                {{ $t("Set.cd7@cancel") }}
              </button>
            </div>
          </form>
          <!-- btns -->
        </tab-content-item>
        <!-- Payment password -->
        <tab-content-item :active="false||$route.query.id==1" id="family-info-payment-password"
          aria-labelled-by="pills-family-info">
          <h4 class="mb-3">{{ $t("Set.4fd@payment_password") }}</h4>
          <hr />
          <div v-if="payWord == 0">
            <p class="mb-1">{{ $t("Set.48c@please_enter_the_6_d") }} :</p>
            <div class="wrap">
              <input class="pwd" v-model="pass1_1" @input="onload" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" type="password" v-model="pass1_2" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" type="password" v-model="pass1_3" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" type="password" v-model="pass1_4" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" type="password" v-model="pass1_5" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" type="password" v-model="pass1_6" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </div>
            <p class="mb-1">{{ $t("Set.395@please_enter_it_a") }} :</p>
            <div class="twice">
              <input class="pwd" v-model="pass2_1" @input="ontwice" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass2_2" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass2_3" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass2_4" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass2_5" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass2_6" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </div>
            <div>
              <b-button variant="primary" type="submit" class="mt-3 mr-2" @click="editUsers">{{ $t("Set.ca8@submit") }}
              </b-button>
              <b-button v-if="isHidden" variant="danger" type="reset" class="mt-3" @click="payBack">{{
                $t("Set.be6@back") }}</b-button>
            </div>
          </div>
          <div v-else-if="payWord == 1">
            <p class="mb-5">{{ $t("Set.3fa@payment_password_h") }}</p>
            <div style="align-items: center">
              <b-button variant="primary" type="submit" class="mr-3 mt-2" @click="payWord = 2">{{ $t("Set.022@modify")
                }}</b-button>
              <a href="javascript:;" @click="forgotPayword">{{ $t("Set.c79@forgot_password") }}?</a>
            </div>
          </div>
          <div v-else-if="payWord == 2">
            <p class="mb-1">{{ $t("Set.b23@current_password") }} :</p>
            <div class="current">
              <input class="pwd" @input="onCurrent" v-model="pass1_1" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass1_2" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass1_3" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass1_4" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass1_5" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
              <input class="pwd" v-model="pass1_6" type="password" maxlength="1"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </div>
            <a href="javascript:;" @click="forgotPayword">{{ $t("Set.c79@forgot_password") }}?</a>
            <div>
              <b-button variant="primary" type="submit" class="mt-3 mr-2" @click="verifyPaymentPassword">{{
                $t("Set.de6@next") }}</b-button>
              <b-button variant="danger" type="reset" @click="resetPayword" class="mt-3">{{ $t("Set.cd7@cancel") }}
              </b-button>
            </div>
          </div>
          <div v-else-if="payWord == 3">
            <p class="mb-5">{{ $t("Set.a3b@payment_password_has") }}</p>
            <div class="p-2" style="color: #ffba68; background: #fffcf3">
              {{ $t("Set.861@check_your_email_for") }}
            </div>
            <div style="align-items: center" class="mt-2">
              <b-button variant="primary" type="submit" class="mr-3" @click="payWord = 2">{{ $t("Set.022@modify") }}
              </b-button>
              <a href="javascript:;" @click="forgotPayword">{{ $t("Set.c79@forgot_password") }}?</a>
            </div>
          </div>
        </tab-content-item>
        <!-- Google 2FA -->
        <tab-content-item :active="false" id="family-info-google-2fa" aria-labelled-by="pills-family-info">
          <h4 class="mb-3">Google Authenticator</h4>
          <hr />
          <b-modal id="verificationCode" centered title="Close Google Authenticator Verification" hide-footer>
            <section>
              <label for="verification-Code">Verification Code</label>
              <input type="text" v-model="verification_code" placeholder="Please enter the verification code"
                class="form-control" id="verification-Code" />
              <div class="WarningDesc mt-3">
                Verification code has been sent to your email, please check
              </div>
              <b-button variant="primary" type="submit" class="mt-3 float-right mb-2" @click="updateUserData()">Confirm
              </b-button>
              <b-button variant="secondary" type="reset" @click="resetVerification_code" class="mt-3 float-right mr-2">
                Cancel</b-button>
            </section>
          </b-modal>
          <p class="titleColor">
            Set up another layer of security using Google Authenticator.
          </p>


          <div v-if="!isShow">
            <form class="mt-4">
              <div class="form-group" v-for="(v, i) in problemsArr" :key="i">
                <label>{{ v.types_name }}</label>
                <select class="form-control mb-4" v-model="selectedList[i]">
                  <option v-for="(v, i) in v.types_and_problems" :key="i" placeholder="China" :value="v">
                    {{ v.problems_name }}
                  </option>
                </select>
                <input type="text" class="form-control mb-0" id="exampleInputQuestion1" placeholder="Answer"
                  v-model="answerList[i]" />
              </div>
            </form>

            <div class="mt-5">
              <button type="submit" class="btn btn-primary mr-2" @click="handleClickNext">
                Next
              </button>
            </div>
          </div>
          <div class="gooogle2FA" v-else>
            <div class="d-flex">
              <div class="switchRight">Google Authenticator:</div>
              <b-form-checkbox style="display: block" switch v-model="is_2fa" @change="judgeFa"></b-form-checkbox>
            </div>
            <div v-if="info_2fa != 1">
              <div class="mt-3">Scan the QR code below on your Google Authenticator application to connect the 2 Factor
                Authentication to your account's sign in.</div>
              <div class="qrcodeBox">
                <vueQr :text="qrCodeUrl"></vueQr>
              </div>
              <!-- <div class="d-flex flex-column desc">
                <span>The key is used to retrieve the Google validator.</span>
                <span>Please save it properly.</span>
              </div> -->

              <div class="form-group">
                <label for="exampleInputSecretKey">Secret Key (Store this somewhere safely as a backup code.)</label>
                <input type="text" class="form-control mb-2" id="exampleInputSecretKey" placeholder="123456" disabled
                  v-model="googleQrcode.key" />

                <p class="
                                d-flex
                                justify-content-center
                                align-items-center
                                titleWarningDesc
                              ">
                  JCI uses security questions to provide you with a secondary method
                  to identify yourself online when you've lost your Google Authenticator.
                </p>
              </div>

              <div class="form-group">
                <label for="exampleInputGACode">Google Authenticator Code (Enter in the code from the authenticator
                  application)</label>
                <input type="text" class="form-control mb-0" id="exampleInputGACode" placeholder="" v-model="token" />
              </div>
            </div>
            <div class="mt-5">
              <button type="submit" class="btn btn-primary mr-2" @click="bindGoogleToken">
                Submit
              </button>
              <button type="reset" @click="resetCode" class="btn btn-danger">
                Cancel
              </button>
            </div>
          </div>
        </tab-content-item>
        <!-- Sign-in Logs -->
        <tab-content-item :active="false" id="work-info-sign-in-logs" aria-labelled-by="pills-work-info">
          <h4 class="mb-3">Sign-in Logs</h4>
          <hr />
          <el-table :data="loginLog.data" style="width: 100%">
            <el-table-column align="center" prop="type" label="Type" width="150"></el-table-column>
            <el-table-column align="center" prop="login_ip" label="Login Ip" width="180"></el-table-column>
            <el-table-column align="center" prop="created_at" label="Create At" width="180"></el-table-column>
            <el-table-column align="center" label="National Name">
              <template slot-scope="scope">
                <span v-if="scope.row.nation_name == 'China'">other</span>
                <span v-else>{{ scope.row.nation_name }}</span>
              </template>
            </el-table-column>
          </el-table>
        </tab-content-item>
      </div>
    </div>
  </div>
</template>
<script>
  import tabContentItem from "../../../components/socialvue/tab/tab-content-item.vue";
  export default {
    components: { tabContentItem },
    name: "AccountSecurity",
    data() {
      return {
        // 设置支付验证
        pass1_1: "",
        pass1_2: "",
        pass1_3: "",
        pass1_4: "",
        pass1_5: "",
        pass1_6: "",
        pass2_1: "",
        pass2_2: "",
        pass2_3: "",
        pass2_4: "",
        pass2_5: "",
        pass2_6: "",
        payWord: "", //确认是否设置支付密码pay_password
        // 修改密码表单
        passwordForm: {
          current_password: "",
          password: "",
          password_confirmation: "",
        },
        isHidden: false,

        // 设置2fa 登录问题
        problemsArr: [], // 2fa 问题列表
        selectedList: [], // 问题列表选中项
        answerList: [], // 填写答案
        googleQrcode: {}, // 二维码
        qrCodeUrl: "", // 二维码路径
        token: "",
        is_2fa: "", // 2fa 开启或关闭
        isShow: Boolean, //  Google 2FA  => 隐藏块
        loginLog: {}, // 登录日志
        verification_code: "", //关闭2fa验证码
        info_2fa: "",
      };
    },
    computed: {},
    mounted() { },
    methods: {
      payBack() {
        this.payWord = 1;
      },
      resetPayword() {
        this.pass1_1 = "";
        this.pass1_2 = "";
        this.pass1_3 = "";
        this.pass1_4 = "";
        this.pass1_5 = "";
        this.pass1_6 = "";
        this.pass2_1 = "";
        this.pass2_2 = "";
        this.pass2_3 = "";
        this.pass2_4 = "";
        this.pass2_5 = "";
        this.pass2_6 = "";
      },
      onload() {
        let transactionCodeInput = document.getElementsByClassName("wrap")[0];
        transactionCodeInput.onkeydown = function (e) {
          let target = e.srcElement || e.target;
          let keyCode = e.keyCode;
          let maxLength = parseInt(target.attributes["maxlength"].value, 10);
          let myLength = target.value.length;
          if (myLength >= maxLength) {
            let next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  break;
                } else {
                  next.focus();
                  break;
                }
              }
            }
          } else if (myLength === 0) {
            // Move to previous field if empty (user pressed backspace)
            let previous = target;
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  previous.focus();
                  break;
                } else {
                  break;
                }
              }
            }
          }
        };
      },
      onCurrent() {
        let transactionCodeInput = document.getElementsByClassName("current")[0];
        transactionCodeInput.onkeydown = function (e) {
          let target = e.srcElement || e.target;
          let keyCode = e.keyCode;
          let maxLength = parseInt(target.attributes["maxlength"].value, 10);
          let myLength = target.value.length;
          if (myLength >= maxLength) {
            let next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  break;
                } else {
                  next.focus();
                  break;
                }
              }
            }
          } else if (myLength === 0) {
            // Move to previous field if empty (user pressed backspace)
            let previous = target;
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  previous.focus();
                  break;
                } else {
                  break;
                }
              }
            }
          }
        };
      },
      ontwice() {
        let transactionCodeInput = document.getElementsByClassName("twice")[0];
        transactionCodeInput.onkeydown = function (e) {
          let target = e.srcElement || e.target;
          let keyCode = e.keyCode;
          let maxLength = parseInt(target.attributes["maxlength"].value, 10);
          let myLength = target.value.length;
          if (myLength >= maxLength) {
            let next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  break;
                } else {
                  next.focus();
                  break;
                }
              }
            }
          } else if (myLength === 0) {
            // Move to previous field if empty (user pressed backspace)
            let previous = target;
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                if (keyCode == 8) {
                  previous.focus();
                  break;
                } else {
                  break;
                }
              }
            }
          }
        };
      },
      passIntType() {
        if (this.$refs.password.type == "text") {
          this.$refs.password.type = "password";
          this.$refs.eye.style.color = "#ccc";
        } else {
          this.$refs.password.type = "text";
          this.$refs.eye.style.color = "#50b5ff";
        }
      },
      passNewInt() {
        if (this.$refs.password1.type == "text") {
          this.$refs.password1.type = "password";
          this.$refs.eye1.style.color = "#ccc";
        } else {
          this.$refs.password1.type = "text";
          this.$refs.eye1.style.color = "#50b5ff";
        }
      },
      changeType() {
        if (this.$refs.password2.type == "text") {
          this.$refs.password2.type = "password";
          this.$refs.eye2.style.color = "#ccc";
        } else {
          this.$refs.password2.type = "text";
          this.$refs.eye2.style.color = "#50b5ff";
        }
      },
      // 忘记支付密码
      forgotPayword() {
        this.$http.forgotPaymentPassword().then((res) => {
          if (res.status == 200) {
            this.payWord = 3;
            this.pass1_1 = "";
            this.pass1_2 = "";
            this.pass1_3 = "";
            this.pass1_4 = "";
            this.pass1_5 = "";
            this.pass1_6 = "";
            this.pass2_1 = "";
            this.pass2_2 = "";
            this.pass2_3 = "";
            this.pass2_4 = "";
            this.pass2_5 = "";
            this.pass2_6 = "";
          }
        });
      },
      // 验证支付密码
      verifyPaymentPassword() {
        if (
          this.pass1_1 != "" &&
          this.pass1_2 != "" &&
          this.pass1_3 != "" &&
          this.pass1_4 != "" &&
          this.pass1_5 != "" &&
          this.pass1_6 != ""
        ) {
          let word = `${this.pass1_1}${this.pass1_2}${this.pass1_3}${this.pass1_4}${this.pass1_5}${this.pass1_6}`;
          this.$http.verifyPaymentPassword({ pay_password: word }).then((res) => {
            if (res.status == 200) {
              this.payWord = 0;
              this.isHidden = true;
              this.pass1_1 = "";
              this.pass1_2 = "";
              this.pass1_3 = "";
              this.pass1_4 = "";
              this.pass1_5 = "";
              this.pass1_6 = "";
              this.pass2_1 = "";
              this.pass2_2 = "";
              this.pass2_3 = "";
              this.pass2_4 = "";
              this.pass2_5 = "";
              this.pass2_6 = "";
            }
          });
        } else {
          this.$message.warning("Please enter the current password");
        }
      },
      // 设置支付密码
      editUsers() {
        if (
          this.pass1_1 !== "" &&
          this.pass1_2 !== "" &&
          this.pass1_3 !== "" &&
          this.pass1_4 !== "" &&
          this.pass1_5 !== "" &&
          this.pass1_6 !== "" &&
          this.pass1_1 == this.pass2_1 &&
          this.pass1_2 == this.pass2_2 &&
          this.pass1_3 == this.pass2_3 &&
          this.pass1_4 == this.pass2_4 &&
          this.pass1_5 == this.pass2_5 &&
          this.pass1_6 == this.pass2_6
        ) {
          let word = `${this.pass1_1}${this.pass1_2}${this.pass1_3}${this.pass1_4}${this.pass1_5}${this.pass1_6}`;
          this.$http.editUsers({ pay_password: word }).then((res) => {
            if (res.status == 200) {
              // console.log(res);
              this.getUserInfo();
              this.pass1_1 = "";
              this.pass1_2 = "";
              this.pass1_3 = "";
              this.pass1_4 = "";
              this.pass1_5 = "";
              this.pass1_6 = "";
              this.pass2_1 = "";
              this.pass2_2 = "";
              this.pass2_3 = "";
              this.pass2_4 = "";
              this.pass2_5 = "";
              this.pass2_6 = "";
            }
          });
        } else {
          this.$message.info("Please re-enter");
        }
      },
      getUserInfo() {
        this.$http.getUserInfo().then((res) => {
          if (res.status == 200) {
            this.payWord = res.data.pay_password;
            this.info_2fa = res.data.is_2fa;
            if (res.data.is_2fa == 1) {
              this.is_2fa = true;
            } else {
              this.is_2fa = false;
            }
            if (res.data.is_2fa_problem == 1) {
              this.isShow = true;
            } else {
              this.isShow = false;
            }
          }
        });
      },
      // 修改2FA
      resetCode() {
        this.token = "";
      },
      // 修改密码
      reset() {
        this.passwordForm.current_password = "";
        this.passwordForm.password = "";
        this.passwordForm.password_confirmation = "";
      },
      /// ///////////////////////////// 修改密码 //////////////
      // 修改密码
      updatePassword() {
        if (
          this.passwordForm.password !== "" &&
          this.passwordForm.password_confirmation !== "" &&
          this.passwordForm.current_password !== ""
        ) {
          const data = { ...this.passwordForm };
          if (this.passwordForm.password == this.passwordForm.current_password) {
            this.$message.warning('The new password cannot be the same as the old password')
            return false
          }
          if (this.passwordForm.password == this.passwordForm.password_confirmation) {
            this.$http.updatePassword(data).then((res) => {
              if (res.status == 200) {
                this.passwordForm.password = "";
                this.passwordForm.password_confirmation = "";
                this.passwordForm.current_password = "";
              }
            });
          } else {
            this.$message.info("Please keep the same password twice");
          }
        } else {
          this.$message.info("Password cannot be empty");
        }
      },
      // 获取2fa 问题列表
      problemsList() {
        const data = {};
        this.$http.problemsList(data).then((res) => {
          if (res.status == 200) {
            this.problemsArr = res.data;
          }
        });
      },
      // 设置2fa 登录问题
      login2faProblemAdd() {
        const data = {};
        this.$http.login2faProblemAdd(data).then((res) => {
          // console.log(res);
          if (res.status == 200) {
          }
        });
      },
      // 点击下一步显示隐藏块  => Next
      handleClickNext() {
        let problem_id = [];
        let problem = [];
        let answer = [];
        this.selectedList.forEach((v) => {
          problem_id.push(v.id);
          problem.push(v.problems_name);
        });
        problem_id = problem_id.join(",");
        problem = problem.join(",");
        answer = this.answerList.join(",");
        const data = {
          problem_id,
          problem,
          answer,
        };
        this.$http.login2faProblemAdd(data).then((res) => {
          // console.log(res);
          if (res.status == 200) {
            this.isShow = true;
          }
        });
      },
      // 判断2fa操作开启或关闭
      judgeFa() {
        if (this.is_2fa == true) {
          this.is_2fa == false;
          if (this.info_2fa == 1) {
            this.off2faEmail();
            this.is_2fa = true;
            this.$bvModal.show("verificationCode");
            this.verification_code = "";
          } else {
            this.is_2fa = true;
          }
        } else if (this.is_2fa == false) {
          this.is_2fa == true;
          if (this.info_2fa == 1) {
            this.off2faEmail();
            this.is_2fa = true;
            this.$bvModal.show("verificationCode");
            this.verification_code = "";
          }
        }
      },
      resetVerification_code() {
        this.$bvModal.hide("verificationCode");
        this.getUserInfo();
      },
      // 关闭2fa发送邮件
      off2faEmail() {
        this.$http.off2faEmail().then((res) => { });
      },
      // 2fa 关闭
      updateUserData() {
        let num;
        this.is_2fa == true ? (num = 1) : (num = 0);
        if (num == "1") {
          this.$http
            .updateUserData({ is_2fa: num ? 0 : 1, code: this.verification_code })
            .then((res) => {
              if (res.status == 200) {
                this.getUserInfo();
                this.getGoogleQrcode();
                this.$bvModal.hide("verificationCode");
              }
            });
        }
      },
      // 获取二维码
      getGoogleQrcode() {
        this.$http.getGoogleQrcode({}).then((res) => {
          if (res.status == 200) {
            this.googleQrcode = res.data;
            // console.log("googleQrcode",this.googleQrcode);
            this.qrCodeUrl = res.data.qrCodeUrl;
            this.is_2fa = Boolean(res.data.user.is_2fa);
          }
        });
      },
      // 绑定2fa  开启2fa
      bindGoogleToken() {
        if (this.token && this.googleQrcode.key) {
          const data = {
            token: this.token,
            secretKey: this.googleQrcode.key,
          };
          this.$http.bindGoogleToken(data).then((res) => {
            // console.log(res);
            this.token = "";
            this.getUserInfo();
          });
        }
      },
      // 获取登录日志
      loginLogList() {
        const params = {};
        this.$http.loginLogList(params).then((res) => {
          if (res.status == 200) {
            this.loginLog = res.data;
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .passwordInt {
    position: relative;

    .ri-eye-line {
      position: absolute;
      top: 43px;
      right: 10px;
      color: #ccc;
    }
  }

  .WarningDesc {
    color: #ffba68;
    background: #fffcf3;
    padding: 10px 10px;
    margin-bottom: 10px;
  }

  .pwd {
    width: 45px;
    height: 45px;
    margin: 10px;
    opacity: 1;
    border: 1px solid #f1f1f1;
    border-radius: 11px;
    text-align: center;
    vertical-align: middel;
  }

  .AccountSecurity {
    ::v-deep .table {
      border-color: #e9ecef;

      thead {
        background: #f4f7f9;

        th {
          border-bottom: none;
          border: none;
        }
      }
    }

    .tab-content {
      .titleColor {
        font-size: 16px;
        color: #333333;
        line-height: 24px;
      }

      

      .gooogle2FA {
        .switchRight {
          margin-right: 140px;
        }

        .qrcodeBox {
          width: 100px;
          height: 100px;
          margin: 0px 0 19px;

          img {
            width: 100%;
          }
        }

        .desc {
          color: #999999;
          margin-bottom: 28px;
        }
      }
    }
  }
@media (min-width: 600px) {
  .titleWarningDesc {
    color: #ffba68;
    height: 70px;
    background: #fffcf3;
    padding: 0 55px 0 19px;
  }
}
@media (max-width: 600px) {
  ::v-deep .nav-pills .nav-item a{
    font-size: 12px;
  }
  .titleWarningDesc {
    color: #ffba68;
    height: 140px;
    background: #fffcf3;
    padding: 0 10px;
  }
}
</style>