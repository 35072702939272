<template>
  <div>
    <!-- Membership  11111 -->
    <div ref="floor1" class="floor1">
      <div class="row pb-5">
        <div class="col-sm-3 col-3 titleColor">{{ $t("Set.440@user_type") }}</div>
        <div class="col-sm-9 col-9">{{ $t("Set.10c@guest") }}</div>
      </div>
      <div class="mt-5 mb-3">
        <p>{{ $t("Set.e0a@select_an_account") }}</p>
        <select class="form-control applyType" v-model="type">
          <option value="0">{{ $t("Set.3ce@member") }}</option>
          <!-- <option value="1">{{ $t("Set.ab4@alumni") }}</option> -->
          <option value="2">{{ $t("Set.a6d@probationary_member") }}</option>
          <!-- <option value="2">Partner</option>
          <option value="3">Sponsor</option> -->
        </select>
        <button type="submit" class="btn btn1" @click="become">
          {{ $t("Set.ca8@submit") }}
        </button>
      </div>
    </div>
    <div ref="floor5">
      <div class="row pb-5">
        <div class="col-sm-3 col-3 titleColor">{{ $t("Set.440@user_type") }}</div>
        <div class="col-sm-9 col-9">{{ $t("Set.10c@guest") }}</div>
      </div>
      <div>
        <p class="p-2" style="color: #ffaea1; background: #fff0f0" v-if="list&&list.status_text">
          Your membership application was declined. The reason is
          {{ list.status_text }}
        </p>
      </div>
      <div class="mt-5 mb-3">
        <button type="submit" class="btn btn1" @click="changeVip">
          Re-apply
        </button>
      </div>
    </div>
    <!-- Membership 222 -->
    <div ref="floor2" class="floor2">
      <!-- form 表单 -->
      <div>
        <div class="form-group">
          <label>{{ $t("Set.018@national_organizatio") }}</label>
          <el-select class="w-100" v-model="organization" filterable value-key="id" @change="
              getLocalList(organization, 3);
              getLocalList(organization, 1);
            ">
            <el-option v-for="v in organizationList" :key="v.id" :label="v.name" :value="v">
              <!-- {{ v.name }} -->
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label>{{ $t("Set.bb4@regional_organizatio") }}</label>
          <el-select class="w-100" v-show="regionalList.length > 0" filterable v-model="region" value-key="id"
            @change="getLocalList(region, 1)">
            <el-option v-for="v in regionalList" :label="v.name" :key="v.id" :value="v">
            </el-option>
          </el-select>
          <el-select class="w-100" v-model="region" v-show="regionalList.length<1" disabled>
            <el-option value="">{{
              $t("Set.c78@there_are_no_regiona")
              }}</el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label>{{ $t("Set.e5a@local_organization") }}</label>
          <el-select class="w-100" filterable v-show="localList.length > 0" v-model="local">
            <el-option v-for="v in localList" :label="v.name" :key="v.id" :value="v.id">
            </el-option>
          </el-select>
          <el-select class="w-100" v-model="local" v-show="localList.length<1" disabled>
            <el-option value="">{{
              $t("Set.bc5@there_are_no_local")
              }}</el-option>
          </el-select>
        </div>
      </div>
      <!-- btns -->
      <div class="btns mt-5 mb-3" v-if="!this.status || this.status == 0">
        <button type="submit" class="btn btn-primary mr-2" @click="applyVip()">
          {{ $t("Set.ca8@submit") }}
        </button>
        <button type="submit" class="btn btn-danger" @click="back">
          {{ $t("Set.be6@back") }}
        </button>
      </div>
      <div class="btns mt-5 mb-3" v-else>
        <button type="submit" class="btn btn-primary mr-2" @click="editVip()">
          {{ $t("Set.ca8@submit") }}
        </button>
      </div>
    </div>
    <!-- Membership 333-->
    <div ref="floor3" class="floor3">
      <div class="row">
        <div class="col-sm-3 col-3 titleColor">{{ $t("Set.440@user_type") }}</div>
        <div class="col-sm-9 col-9">{{ $t("Set.10c@guest") }}</div>
      </div>
      <div class="mt-5 mb-3">
        <el-button type="info" plain disabled>{{
          $t("Set.2b3@under_review")
          }}</el-button>
        <a href="javascript:;" style="color: #ff9b8a; text-decoration: underline; margin-left: 15px" @click="delVip">{{
          $t("Set.cf1@withdraw_the_appli") }}</a>
      </div>
    </div>
    <!-- Membership  444 -->
    <div ref="floor4" class="floor4" v-if="list">
      <div class="row mb-5 m-0">
        <div class="col-sm-8 col-11">
          <div class="row">
            <!-- User Type -->
            <div class="col-sm-4 col-6 titleColor">{{ $t("Set.440@user_type") }}</div>
            <div class="col-sm-8 col-6" v-if="list.type == 0">
              {{ $t("Set.3ce@member") }}
            </div>
            <!-- <div class="col-sm-8" v-else-if="list.type == 1">
              {{ $t("Set.ab4@alumni") }}
            </div> -->
            <div class="col-sm-8 col-6" v-else-if="list.type == 2">
              {{ $t("Set.a6d@probationary_member") }}
            </div>
          </div>
          <div class="row">
            <!-- Chapter -->
            <div class="col-sm-4 col-6 titleColor my-2">
              {{ $t("Set.c0c@organization") }}
            </div>
            <div class="col-sm-6 col-4 my-2" v-if="infoList && infoList.name">
              {{ infoList.name }}
            </div>
            <div class="col-sm-2 col-2 my-2">
              <a href="javascript:;" style="color: #ff9b8a; text-decoration: underline" @click="changeVip">{{
                $t("Set.4fc@change") }}</a>
            </div>
          </div>
          <div class="row">
            <!-- Member Expire -->
            <div class="col-sm-4 col-6 titleColor">
              {{ $t("Set.664@member_expire") }}
            </div>
            <div class="col-sm-8 col-6">{{ list.out_date }}</div>
          </div>
          <div class="row">
            <!-- 会费 -->
            <div class="col-sm-4 col-6 titleColor my-2">
              {{ $t("Set.d31@membership_fee") }}
            </div>
            <div class="col-sm-8 col-6 titleColor my-2" v-if="list.type == 0">
              ${{ infoList.membership_fee }}
            </div>
            <!-- <div class="col-sm-8 titleColor my-2" v-if="list.type == 1">
              ${{ infoList.alumni_fees }}
            </div> -->
            <div class="col-sm-8 col-6 titleColor my-2" v-if="list.type == 2">
              ${{ infoList.reserve_membership_fee }}
            </div>
          </div>
          <div v-if="list.order_status == 6">
            <p class="p-2" style="color: #ffaea1; background: #fff0f0">
              Your offline payment was declined
            </p>
            <p class="mt-2" style="color: #ffaea1">
              The Reason: {{ list.remarks }}
            </p>
            <div class="mt-3">
              <button id="payButtonId" @click="
                  $router.push({
                    path: '/PayMember',
                    query: { type: 5, id: applyId },
                  })
                " class="btn btn-primary">
                {{ $t("Set.f25@pay") }}
              </button>
            </div>
          </div>
        </div>
        <!-- btns   -->
      </div>
      <div class="my-3 ml-2" ref="pay" v-if="list.status == 1 && list.order_status != 6">
        <div class="word-wrap: break-word">
          {{ $t("Set.c3d@the_membership_you") }}
        </div>
        <!-- <el-button disabled v-if="list.type == 0 && infoList.membership_fee == '0.00'" type="info">
          {{ $t("Set.f25@pay") }}
        </el-button> -->

        <!-- <el-button v-else-if="list.type == 1 && infoList.alumni_fees == '0.00'" type="info" disabled>
          {{ $t("Set.f25@pay") }}
        </el-button> -->

        <!-- <el-button disabled v-else-if="
            list.type == 2 && infoList.reserve_membership_fee == '0.00'
          " type="info">
          {{ $t("Set.f25@pay") }}
        </el-button> -->

        <button id="payButtonId" @click="handlePay" class="btn btn-primary">
          {{ $t("Set.f25@pay") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        isShow: false,
        type: "0",
        organizationList: [],
        regionalList: [],
        localList: [],
        organization: {},
        region: {},
        local: "",
        national: 0,
        infoList: {},
        isShowBtn: false,
        status: "",
        list: {},
        applyId: "",
      };
    },
    props: ["userList"],
    computed: {
      ...mapGetters({
        hppResponse: "Public/getHppResponse",
        payStatus: "Public/getPayStatus",
      }),
    },
    watch: {
      hppResponse: {
        deep: true,
        handler: function (newData, oldData) {
          this.$http.vipPayResponse({ hppResponse: newData }).then((res) => {
            if (res.status == 200) {
              // this.$store.commit('Public/CHANGE_PAY_STATUS', true)
              this.isShowBtn = true;
              this.$message.success(res.message);
            }
          });
        },
      },
    },
    methods: {
      async handlePay() {
        // this.$router.push({
        //   path: '/PayMember',
        //   query: { type: 5, id: applyId },
        // })
        let res = await this.$http.vipCreateOrder()
        if (res.status == 200) {
          if (res.data.is_jump == 1) {
            this.$router.push({
              path: '/PayMoney',
              query: { model_type: 5, id: res.data.order_id },
            })
          } else {
            this.$router.push({
              path: '/PaySuccess',
              query: { model_type: 5 },
            })
          }
        }
      },

      clickEvent() {
        this.$refs.floor1.style.display = "none";
        this.$refs.floor2.style.display = "none";
        this.$refs.floor3.style.display = "none";
        this.$refs.floor4.style.display = "none";
        this.$refs.floor5.style.display = "none";
        this.applyVipStatus();
        this.getOrganizationList();
        this.getorg();
      },
      async getorg() {
        this.organization = this.userList.organization.national;
        this.organization.parent_id = undefined;
        this.region = this.userList.organization.regional;
        if (
          this.userList.organization.regional &&
          this.userList.organization.organization.id
        ) {
          await this.getLocalList(this.organization, 3);
          await this.getLocalList(this.region, 1);
          this.organization.has_regions = 1;
        } else {
          await this.getLocalList(this.organization, 1);
          this.organization.has_regions = 0;
        }
        this.local = this.userList.organization.organization.id;
      },
      delVip() {
        this.$http.delVip({ id: this.applyId }).then((res) => {
          if (res.status == 200) {
            this.applyVipStatus();
          }
        });
      },
      changeVip() {
        this.$refs.floor1.style.display = "none";
        this.$refs.floor2.style.display = "block";
        this.$refs.floor3.style.display = "none";
        this.$refs.floor4.style.display = "none";
        this.$refs.floor5.style.display = "none";
      },
      editVip() {
        if (this.local) {
          this.$http
            .editVip({
              id: this.applyId,
              organization_id: this.local,
            })
            .then((res) => {
              if (res.status == 200) {
                this.applyVipStatus();
                this.$refs.floor2.style.display = "none";
              }
            });
        } else {
          this.$message("Please select local level");
        }
      },
      back() {
        this.$refs.floor1.style.display = "block";
        this.$refs.floor2.style.display = "none";
      },
      become() {
        this.$refs.floor1.style.display = "none";
        this.$refs.floor2.style.display = "block";
      },
      getOrganizationList() {
        this.organizationList = [];
        this.$http.getOrganizationList({ type: 2 }).then((res) => {
          if (res.status == 200) {
            // this.organizationList = res.data;
            res.data.forEach((item, index) => {
              return this.organizationList.push({
                name: item.name,
                id: item.id,
                parent_id: item.parent_id,
                has_regions: item.has_regions,
              });
            });
          }
        });
      },
      async getLocalList(parent, type) {
        if (type == 3) {
          let res = await this.$http.getOrganizationList({
            parent_id: parent.id,
            type,
          });
          if (res.status == 200) {
            if (type == 3) {
              this.regionalList = [];
              // this.regionalList = res.data;
              res.data.forEach((item, index) => {
                return this.regionalList.push({
                  name: item.name,
                  id: item.id,
                  parent_id: parent.id,
                });
              });
              // console.log("this.regionalList",this.regionalList);
            }
          }
        } else {
          this.local = "";
          if (parent) {
            let res = await this.$http.getOrganizationList({
              parent_id: parent.id,
              type,
            });
            if (res.status == 200) {
              this.localList = [];
              res.data.forEach((item, index) => {
                return this.localList.push({
                  name: item.name,
                  id: item.id,
                });
              });
              // console.log("this.localList",this.localList)
            }
          }
        }
      },
      // 查询会员状态
      applyVipStatus() {
        this.$http.applyVipStatus().then((res) => {
          if (res.status == 200) {
            if (res.data) {
              this.infoList = res.data.vip_organization;
              this.list = res.data;
              this.status = res.data.status;
              this.applyId = res.data.id;
              if (res.data.order_status == 6 && res.data.status == 4) {
                this.$refs.floor1.style.display = "none";
                this.$refs.floor2.style.display = "none";
                this.$refs.floor5.style.display = "none";
                this.$refs.floor3.style.display = "none";
                this.$refs.floor4.style.display = "block";
              } else if (res.data.status == "0") {
                this.$refs.floor1.style.display = "none";
                this.$refs.floor3.style.display = "block";
                this.$refs.floor4.style.display = "none";
              } else if (res.data.status == "1") {
                this.$refs.floor1.style.display = "none";
                this.$refs.floor3.style.display = "none";
                this.$refs.floor4.style.display = "block";
              } else if (res.data.status == "2") {
                this.$refs.floor1.style.display = "none";
                this.$refs.floor2.style.display = "none";
                this.$refs.floor3.style.display = "none";
                this.$refs.floor4.style.display = "none";
                this.$refs.floor5.style.display = "block";
              } else if (res.data.status == "3") {
                this.$refs.floor1.style.display = "none";
                this.$refs.floor2.style.display = "none";
                this.$refs.floor3.style.display = "none";
                this.$refs.floor4.style.display = "block";
                this.isShowBtn = false;
              } else if (res.data.status == "4") {
                this.$refs.floor1.style.display = "none";
                this.$refs.floor2.style.display = "none";
                this.$refs.floor3.style.display = "block";
                this.$refs.floor4.style.display = "none";
              } else {
                this.$refs.floor1.style.display = "block";
                this.$refs.floor2.style.display = "none";
                this.$refs.floor3.style.display = "none";
                this.$refs.floor4.style.display = "none";
              }
            } else {
              this.$refs.floor1.style.display = "block";
              this.$refs.floor2.style.display = "none";
              this.$refs.floor3.style.display = "none";
              this.$refs.floor4.style.display = "none";
            }
          }
        });
      },
      // 申请成为会员
      applyVip() {
        if (this.local) {
          this.$http
            .applyVip({
              type: this.type,
              nation_id: this.national,
              organization_id: this.local,
            })
            .then((res) => {
              if (res.status == 200) {
                this.applyVipStatus();
                this.$refs.floor2.style.display = "none";
                this.organization = {};
                this.region = "";
                this.local = "";
                this.national = "";
              }
            });
        } else {
          this.$message("Please select local level");
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .btn1 {
    color: #fff;
    background-color: #50b5ff;
    border: 3px solid #50b5ff;
  }

  .floor2,
  .floor3,
  .floor4 {
    display: none;
  }

  ::v-deep .el-input__inner {
    height: 45px !important;
    line-height: 45px;
    background: transparent;
    border: 1px solid #ced4da;
    font-size: 14px;
    color: var(--iq-secondary);
    border-radius: 10px;
  }

  .applyType {
    width: 130px !important;
    display: inline-block;
    height: 39px !important;
    border-radius: 5px !important;
    margin-right: 10px;
  }
</style>