<template>
  <div class="AccountSettings">
    <iq-card>
      <div class="row">
        <div class="col-md-3 col-3">
          <!-- 垂直 tab 选项卡  -->
          <tab-nav
            :pills="true"
            id="tab"
            class="nav nav-pills basic-info-items list-inline d-block p-0 pl-3 pt-3 m-0 tabNav"
          >
            <!-- Email -->
            <tab-nav-items
              :active="false || $route.query.type != 1|| tabStatus == 'emailTab'"
              id="pills-contact-info"
              href="#contact-info"
              ariaControls="pills-contact-info"
              role="tab"
              @click.native="tabStatus='emailTab'"
              :ariaSelected="false || $route.query.type != 1"
              :title="$t('Set.a4f@email')"
              class="tab-nav-items-text-color"
            />
            <!-- KYC -->
            <tab-nav-items
              :active="false || tabStatus == 'kycTab'"
              id="pills-family-info"
              href="#family-info"
              ariaControls="pills-family-info"
              role="tab"
              :ariaSelected="true"
              :title="$t('Set.39b@kyc')"
              class="tab-nav-items-text-color"
              @click.native="
              (isShow = false),
                kycList(),
                  ($refs.kyc.style.display = 'none'),
                  ($refs.bank.style.display = 'none'),
                  tabStatus='kycTab'
              "
            />
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              class="bi bi-question-circle"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              @mouseenter="tip()"
              @mouseleave="tipdown()"
            >
              <path
                fill-rule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
              />
            </svg>
            <div class="tips" v-show="isTip">
              {{ $t("Set.a72@here_is_the_kyc_desc") }}
            </div>
            <!-- Membership -->
            <tab-nav-items
              :active="false || $route.query.type == 1 || tabStatus == 'memberTab'"
              id="pills-work-info"
              href="#work-info"
              ariaControls="pills-work-info"
              role="tab"
              :ariaSelected="false || $route.query.type == 1"
              :title="$t('Set.b75@membership')"
              @click.native="childEvent(),tabStatus='memberTab'"
              class="tab-nav-items-text-color"
            />
            <!-- Special Membership -->
            <tab-nav-items
              :active="false || tabStatus == 'specialTab'"
              id="pills-address-info"
              href="#address-info"
              ariaControls="pills-address-info"
              role="tab"
              :ariaSelected="true"
              :title="$t('Set.e52@special_membership')"
              class="tab-nav-items-text-color"
              @click.native="special(),tabStatus='specialTab'"
            />
            <!-- Account Membership -->
            <tab-nav-items
              :active="false|| tabStatus == 'accountTab'"
              id="pills-account-info"
              href="#account-info"
              ariaControls="pills-account-info"
              role="tab"
              @click.native="tabStatus='accountTab'"
              :ariaSelected="true"
              :title="$t('Set.0c1@account_membership')"
              class="tab-nav-items-text-color"
            />
          </tab-nav>
        </div>
        <div class="col-md-9 col-9 paddingChang">
          <div class="tab-content">
            <!-- Email -->
            <tab-content-item
              :active="false || $route.query.type != 1|| tabStatus == 'emailTab'"
              id="contact-info"
              aria-labelled-by="pills-contact-info"
            >
              <h4>{{ $t("Set.a4f@email") }}</h4>
              <hr />
              <!-- 电子邮件输入框 -->
              <p style="color: #333">
                {{ $t("Set.0c4@your_email") }}: {{ user_email }}
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="email"
                  placeholder="Enter New Email"
                />
                <div class="input-group-append">
                  <div class="input-text" @click="sandEditCode()">
                    <a v-if="!submit" href="javascript:;"
                      ><u>{{ $t("Set.4d7@get_verification") }}</u></a
                    >
                    <u v-else>{{ $t("Set.172@resend") }}</u>
                  </div>
                </div>
              </div>
              <div class="emailAddress">
                <input
                  type="text"
                  v-model="codeNum"
                  :placeholder="$t('Set.6d8@please_enter_verific')"
                  class="form-control"
                />
                <div
                  v-if="submit"
                  class="mt-3 mb-3 pt-2 pb-2 pl-2"
                  style="color: #ffba68; background: #fffcf3"
                >
                  {{ $t("Set.786@the_verification_cod") }}
                </div>
              </div>

              <!-- btns -->
              <div class="mb-3 btns">
                <button
                  type="submit"
                  class="btn btn-primary mr-2"
                  @click="editEmail"
                >
                  {{ $t("Set.ca8@submit") }}
                </button>
                <button type="reset" class="btn btnOrange" @click="reset()">
                  {{ $t("Set.cd7@cancel") }}
                </button>
              </div>
            </tab-content-item>
            <!-- KYC -->
            <tab-content-item
              :active="false || tabStatus == 'kycTab'"
              id="family-info"
              aria-labelled-by="pills-family-info"
            >
              <!-- 标题 -->
              <h4 class="mb-3">{{ $t("Set.39b@kyc") }}</h4>
              <hr />
              <p>After your submission, please contact your Local President for KYC account approval.</p>
              <div v-if="!isShow">
                <b-card @click="kyc" class="lev" ref="lev">
                  <div class="row lev1">
                    <div class="col-9">
                      <h4>{{ $t("Set.f6c@level_1_verificati") }}</h4>
                      <p>{{ $t("Set.881@upload_id_card_or") }}</p>
                    </div>
                    <div class="col-3 v-center">
                      <div v-if="kyclist.kyc" @click.stop>
                        <div class="applyStatus">
                          <span
                            style="color: #999999;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;"
                            v-if="kyc_type1.status == '0'"
                            >Under review...</span
                          >
                          <span
                            style="color: #67C23A;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;"
                            v-else-if="kyc_type1 && kyc_type1.status == 1"
                            >Completed</span
                          >
                          <span
                            v-else-if="kyc_type1 && kyc_type1.status == 2"
                            style="color: #ff9b8a;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;"
                          >
                            Rejected
                            <svg
                              @mouseenter="tipRej()"
                              @mouseleave="tipdownRej()"
                              t="1648098281410"
                              class="icon"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="1341"
                              width="16"
                              height="16"
                            >
                              <path
                                d="M512 14.208c274.56 0 497.792 223.168 497.792 497.792 0 274.56-223.168 497.792-497.792 497.792C237.44 1009.792 14.208 786.56 14.208 512 14.208 237.44 237.44 14.208 512 14.208z m0 71.104A427.072 427.072 0 0 0 85.312 512 427.072 427.072 0 0 0 512 938.688 427.072 427.072 0 0 0 938.688 512 427.072 427.072 0 0 0 512 85.312z m35.584 628.16v71.104H476.416v-71.04h71.168z m0-474.048v402.944H476.416V239.424h71.168z"
                                p-id="1342"
                                fill="#ff9b8a"
                              ></path>
                            </svg>
                          </span>
                          <div v-show="rejectTip" class="rejectTips">
                            <div v-if="kyc_type1">
                              The Reason: {{ kyc_type1.remarks }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="kyc_type1 && kyc_type1.status == 0"
                          @click="cancelKyc1Apply(kyc_type1.id)"
                          style="
                            text-decoration: underline;
                            color: #ff9b8a;
                            font-size: 12px;
                            max-width:100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            corsur:pointer
                          "
                          >Cancel Application</div
                        >
                      </div>
                      <p v-else style="color:#FFBA68;max-width:100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;">To Authenticate</p>
                    </div>
                  </div>
                </b-card>
                <b-card
                  v-if="kyc_type1.status==1||kyc_type2.status==1"
                  @click="bank()"
                  class="lev"
                  ref="lev"
                >
                  <div class="row lev2">
                    <div class="col-9">
                      <h4>{{ $t("Set.621@level_2_verificati") }}</h4>
                      <p>{{ $t("Set.dc3@upload_bank_card_") }}</p>
                    </div>
                    <div class="col-3 v-center applyStatus" @click.stop>
                      <div style="color: #ffba68;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;" v-if="kyc_type2">
                        <span
                          style="color: #999999"
                          v-if="kyc_type2.status == '0'"
                          >Under review...</span
                        >
                        <span
                          style="color: #67C23A;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;"
                          v-else-if="kyc_type2.status == 1"
                          >Completed</span
                        >
                        <span
                          v-else-if="kyc_type2.status == 2"
                          style="color: #ff9b8a;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;"
                        >
                          Rejected
                          <svg
                            @mouseenter="tipBank()"
                            @mouseleave="tipdownBank()"
                            t="1648098281410"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="1341"
                            width="16"
                            height="16"
                          >
                            <path
                              d="M512 14.208c274.56 0 497.792 223.168 497.792 497.792 0 274.56-223.168 497.792-497.792 497.792C237.44 1009.792 14.208 786.56 14.208 512 14.208 237.44 237.44 14.208 512 14.208z m0 71.104A427.072 427.072 0 0 0 85.312 512 427.072 427.072 0 0 0 512 938.688 427.072 427.072 0 0 0 938.688 512 427.072 427.072 0 0 0 512 85.312z m35.584 628.16v71.104H476.416v-71.04h71.168z m0-474.048v402.944H476.416V239.424h71.168z"
                              p-id="1342"
                              fill="#ff9b8a"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <div v-else style="color:#FFBA68;max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;">To Authenticate</div>
                      <div
                        v-if="kyc_type2 && kyc_type2.status == '0'"
                        @click="cancelBank2Apply(kyc_type2.id)"
                        style="
                          text-decoration: underline;
                          color: #ff9b8a;
                          font-size: 12px;
                          max-width:100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          corsur:pointer
                        "
                        >Cancel Application</div
                      >
                      <div v-show="rejectBankTip" class="rejectTips">
                        <div v-if="kyc_type2">
                          The Reason: {{ kyc_type2.remarks }}
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </div>
              <div ref="kyc" class="kyc">
                <Kyc1
                  ref="childKYC1"
                  :isEdit="isEdit"
                  @previous="previous"
                  @cancelApply="cancelKyc1Apply"
                ></Kyc1>
              </div>
              <div ref="bank" class="bank">
                <Kyc2
                  ref="childKYC2"
                  @previous="previous"
                  @cancelApply="cancelBank2Apply"
                  :nationalList="countryList"
                ></Kyc2>
              </div>
            </tab-content-item>
            <!-- Membership -->
            <tab-content-item
              :active="false || $route.query.type == 1 || tabStatus == 'memberTab'"
              id="work-info"
              aria-labelled-by="pills-work-info"
            >
              <h4 class="mb-3">{{ $t("Set.b75@membership") }}</h4>
              <hr />
              <!-- 隐藏块     == > 点击按钮 -->
              <Membership ref="child" :userList="userList"></Membership>
            </tab-content-item>
            <!-- Special Membership -->
            <tab-content-item
              :active="false || tabStatus == 'specialTab'"
              id="address-info"
              aria-labelled-by="pills-address-info"
            >
              <SeetingSpecal
                @checkTab="checkoutTab"
                ref="setSpecial"
              ></SeetingSpecal>
            </tab-content-item>
            <!-- Account Membership -->
            <tab-content-item
              :active="false || tabStatus == 'accountTab'"
              id="account-info"
              aria-labelled-by="pills-account-info"
            >
              <AccountMembership></AccountMembership>
            </tab-content-item>
          </div>
        </div>
      </div>
    </iq-card>
  </div>
</template>
<script>
import Kyc1 from "../../../components/ComComponents/Kyc1";
import Kyc2 from "../../../components/ComComponents/Kyc2";
import SeetingSpecal from "../../../components/ComComponents/SeetingSpecal";
import Membership from "../../../components/ComComponents/Membership";
import AccountMembership from "../../../components/ComComponents/AccountMembership";
import { Loading } from "element-ui";
export default {
  name: "About",
  mounted() {
    this.getUserInfo();
  },
  components: {
    Kyc1,
    Kyc2,
    SeetingSpecal,
    Membership,
    AccountMembership,
  },
  data() {
    return {
      kyc_type1: {},
      kyc_type2: {},
      isEdit: false,
      countryList: [],
      submit: false,
      isShow: false, // 控制隐藏块  => hiddenBlock
      isTip: false,
      rejectTip: false,
      rejectBankTip: false,
      specalList: {
        role_id: "",
        company_name: "",
        business_id: "",
        business_address: "",
        merchants_prove: "",
      },
      email: "",
      user_email: "",
      codeNum: "",
      kyclist: {},
      userList: [],
      tabStatus: "",
    };
  },
  methods: {
    checkoutTab(tab) {
      this.tabStatus = tab;
      this.$refs.setSpecial.membership = 'Merchant';
      this.$refs.setSpecial.status = 'Company';
    },
    cancelKyc1Apply(id) {
      this.$confirm("Are you sure to cancel the application?", "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$http
          .cancelKyc({
            kyc_id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.kycList();
              this.$refs.childKYC1.back();
              this.$refs.childKYC1.clearBack();
            }
          });
      });
    },
    cancelBank2Apply(id) {
      this.$confirm("Are you sure to cancel the application?", "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$http
          .delBank({
            bank_id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.kycList();
              this.$refs.childKYC2.back();
              this.$refs.childKYC2.clearBank();
            }
          });
      });
    },
    tipBank() {
      this.rejectBankTip = true;
    },
    tipdownBank() {
      this.rejectBankTip = false;
    },
    tipRej() {
      this.rejectTip = true;
    },
    tipdownRej() {
      this.rejectTip = false;
    },
    // 取消申请
    special() {
      this.$refs.setSpecial.getKycstatus();
      this.$refs.setSpecial.getProductClassify();
      this.$refs.setSpecial.applyList();
    },
    tip() {
      this.isTip = true;
    },
    tipdown() {
      this.isTip = false;
    },
    // 获取 kyc状态
    // kyc列表
    async kycList() {
      let res = await this.$http.kycList();
      if (res.status == 200) {
        this.kyclist = res.data;
        this.kyc_type1 = res.data.kyc||{};
        this.kyc_type2 = res.data.kyc_bank||{};
        // this.kyclist.forEach((v) => {
        //   if (v.photo_of_id_card) {
        //   } else if (v.kyc_type == 2) {
        //   }
        // });
        // console.log("kyc", res.data);
      }
    },
    childEvent() {
      this.$refs.child.clickEvent();
    },
    // 获取用户信息
    getUserInfo() {
      this.$http.getUserInfo().then((res) => {
        if (res.status == 200) {
          this.codeNum = "";
          this.user_email = res.data.email;
          this.userList = res.data;
        }
      });
    },
    // 获取验证码
    sandEditCode() {
      this.$http
        .sandEditCode({
          email: this.email,
          type: 1,
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.submit = true;
          }
        });
    },
    // 编辑用户邮箱
    editEmail() {
      this.$http
        .editEmail({
          email: this.email,
          verification_code: this.codeNum,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("Modified successfully");
          }
        });
    },
    reset() {
      this.email = "";
      this.codeNum = "";
    },
    checkType() {
      this.areShow = true;
    },
    // //////会员///////
    // 查询会员状态
    applyVipStatus() {
      this.$http.applyVipStatus().then((res) => {
        if (res.status == 200) {
          // console.log(res)
        }
      });
    },
    // 申请成为会员
    applyVip(nationalId, chapterId) {
      // console.log(nationalId, chapterId),
      this.$http
        .applyVip({
          nation_id: nationalId,
          organization_id: chapterId,
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res);
          }
        });
    },
    // 获取国家信息
    getAddress() {
      this.$http.getAddress({ level: 2 }).then((res) => {
        if (res.status == 200) {
          this.countryList = res.data;
          // console.log(res.data);
        }
      });
    },
    previous() {
      this.isShow = false;
      this.kycList();
      this.$refs.kyc.style.display = "none";
      this.$refs.bank.style.display = "none";
    },
    // 判断kyc form表单和bank form表单
    kyc() {
      if (this.kyclist.kyc) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      this.isShow = true;
      this.$refs.kyc.style.display = "block";
      this.$refs.childKYC1.myKyc();
    },
    async bank() {
      await this.kycList();
      if (this.kyclist.kyc_bank) {
        let id = this.kyc_type2.id;
        this.isShow = true;
        this.$refs.bank.style.display = "block";
        this.$refs.childKYC2.getBankFirst(id);
        this.getAddress();
      } else {
        this.isShow = true;
        this.$refs.bank.style.display = "block";
        this.getAddress();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-link.active{
  width: 98% !important;
}
.row{
  padding-left: 1px !important;
  padding-right: 1px !important;
}
@media (min-width: 600px) {
  .paddingChang{
    padding-top: 1.5rem !important;
    padding-right: 1.5rem!important;
    overflow: hidden;
  }
  .tabNav {
    svg {
      right: 15px;
      top: 68px;
    }
  }
}
@media (max-width: 600px) {
  ::v-deep .nav-pills .nav-item a{
    font-size: 12px;
  }
  .tabNav {
    svg {
      right: 0px;
      top: 55px;
    }
    }
  .paddingChang{
    padding: 1.5rem !important;
  }
}
.AccountSettings {
  .tabNav {
    position: relative;
    svg {
      position: absolute;
      color: #ffba68;
    }
    .tips {
      position: absolute;
      left: 30px;
      top: 90px;
      padding: 5px;
      background-color: #eee;
      border: 1px solid rgba(0, 0, 0, 0.02);
      border-radius: 5px;
    }
  }
  .applyStatus {
    position: relative;
    max-width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .rejectTips {
      position: absolute;
      top: 20px;
      right: 0;
      padding: 10px;
      min-width: 500px;
      text-align: left;
      background: #fff5f4;
      font-size: 14px;
      font-weight: 300;
      color: #ff9b8a;
    }
  }
  .tab-content {
    .emailAddress {
      margin: 20px 0 30px;
    }
    .titleColor {
      color: #333333;
    }
    .hiddenBlock {
      .btns {
        margin-top: 113px;
      }
    }
  }
  .kyc {
    display: none;
  }
  .bank {
    display: none;
  }
  .card-body {
    padding: 0;
  }
  .input-group {
    input {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .input-group-append {
      border: 1px solid #ced4da;
      border-left: 0;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-right: 10px;
      .input-text {
        background: #fff;
        color: #50b5ff;
        line-height: 2.8;
      }
    }
  }
  .lev1 {
    background: #f8f8f8;
    padding: 15px 10px;
    p {
      margin: auto 0;
    }
  }
  .lev1:hover {
    cursor: pointer;
  }
  .lev2:hover {
    cursor: pointer;
  }
  .lev2 {
    box-shadow: 0px 0px 20px #f5f5f5;
    padding: 15px 10px;
    p {
      margin: auto 0;
    }
  }
  .lev {
    margin: 15px 0;
  }
  .v-center {
    margin: auto 0;
    text-align: right;
  }
}
</style>
