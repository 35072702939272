<template>
  <b-row>
    <b-col sm="12">
      <!-- tab 选项卡 -->
      <iq-card>
        <tab-nav :pills="true" id="pills-tab" class="
            nav nav-pills
            d-flex
            align-items-center
            justify-content-center
            profile-feed-items
            p-0
            m-0
          ">
          <tab-nav-items class="col-sm-3 col-3 p-0" :active="false||$route.query.id!=1" id="pills-feed-tab"
            href="#profile-feed" ariaControls="pills-home" role="tab" :ariaSelected="false||$route.query.id!=1"
            :title="$t('Set.c2e@account_settings')" />
          <!-- <tab-nav-items
            class="col-sm-3 p-0"
            :active="false"
            id="pills-activity-tab"
            href="#profile-activity"
            ariaControls="pills-profile"
            role="tab"
            :ariaSelected="false"
            title="Notification Settings"
            @click.native="notificat"
          /> -->
          <tab-nav-items class="col-sm-3 col-3 p-0" :active="false||$route.query.id==1" id="pills-friend-tab"
            href="#profile-friends" ariaControls="pills-contact" role="tab" :ariaSelected="false||$route.query.id==1"
            :title="$t('Set.7a7@account_security')" @click.native="userInfo" />
          <tab-nav-items class="col-sm-3 col-3 p-0" :active="false" id="pills-profile-tab" href="#profile-profile"
            ariaControls="pills-contact" role="tab" :ariaSelected="false" :title="$t('Set.90f@privacy_settings')"
            @click.native="setPrivacy" />
          <tab-nav-items class="col-sm-3 col-3 p-0" :active="false" id="pills-profile-tab" href="#profile-blackList"
            ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Blocklist" @click.native="setBlackList"/>
        </tab-nav>
      </iq-card>
    </b-col>
    <b-col sm="12">
      <tab-content>
        <tab-content-item :active="false||$route.query.id!=1" id="profile-feed" aria-labelled-by="pills-feed-tab">
          <div>
            <AccountSettings />
          </div>
        </tab-content-item>
        <!-- <tab-content-item
          :active="false"
          id="profile-activity"
          aria-labelled-by="pills-activity-tab"
        >
          <div class="iq-card">
            <div class="iq-card-body">
              <NotificationSettings ref="notification" />
            </div>
          </div>
        </tab-content-item> -->
        <tab-content-item :active="false||$route.query.id==1" id="profile-friends" aria-labelled-by="pills-friend-tab">
          <div class="iq-card">
            <div class="iq-card-body">
              <AccountSecurity ref="security" />
            </div>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="profile-profile" aria-labelled-by="pills-profile-tab">
          <div class="iq-card">
            <div class="iq-card-body">
              <PrivacySettings ref="privacy" />
            </div>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="profile-blackList" aria-labelled-by="pills-profile-tab">
          <div class="iq-card">
            <div class="iq-card-body" ref="blackList">
              <BlackList />
            </div>
          </div>
        </tab-content-item>
      </tab-content>
    </b-col>

    <!-- loading -->
    <!-- <div class="col-sm-12 text-center">
      <img
        src="../../assets/images/page-img/page-load-loader.gif"
        alt="loader"
        style="height: 100px"
      />
    </div> -->
  </b-row>
</template>
<script>
  import AccountSecurity from './Components/AccountSecurity'
  import AccountSettings from './Components/AccountSettings'
  import NotificationSettings from './Components/NotificationSettings'
  import PrivacySettings from './Components/PrivacySettings'
  import BlackList from './Components/BlackList'
  export default {
    name: 'Settings',
    mounted() { },
    components: {
      AccountSettings,
      NotificationSettings,
      AccountSecurity,
      PrivacySettings,
      BlackList
    },
    data() {
      return {}
    },
    methods: {
      // notificat(){
      //   this.$refs.notification.getNotification();
      // },
      setPrivacy() {
        this.$refs.privacy.getPrivacy();
      },

      setBlackList() {
        // this.$refs.blackList.getPullBlackList();
      },

      userInfo() {
        this.$refs.security.getUserInfo();
        this.$refs.security.getGoogleQrcode();
        this.$refs.security.problemsList();
        this.$refs.security.loginLogList();
      }
    }
  }
</script>
<style scoped>
 @media (max-width: 600px) {
  ::v-deep .profile-feed-items li a.nav-link {
    font-size: 8px;
    padding: 12px 5px !important;
  }
 }
</style>