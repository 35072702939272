<template>
  <div class="NotificationSettings">
    <h4 class="mb-3">{{ $t("Set.0a7@notification_setting") }}</h4>
    <hr />
    <div class="row">
      <!-- Email Notification : -->
      <div class="col-md-4 mb-4">{{ $t("Set.649@email_notification") }} :</div>
      <div class="col-md-8 mb-4">
        <b-form-checkbox
          style="display: block"
          switch
          v-model="form.email_notification"
        ></b-form-checkbox>
      </div>
      <!-- SMS Notification : -->
      <!-- <div class="col-md-4 my-4">SMS Notification :</div>
      <div class="col-md-8 my-4">
        <b-form-checkbox
          style="display: block"
          switch
          v-model="form.sms_notification"
        ></b-form-checkbox>
      </div> -->
      <!-- When To Inform -->
      <div class="col-md-4">{{ $t("Set.372@when_to_inform") }}</div>
      <div class="col-md-8 d-flex flex-column">
        <label for="exampleCheck1">
          <input
            type="checkbox"
            id="exampleCheck1"
            class="mr-4"
            v-model="form.friend_post"
          />
          {{ $t("Set.b72@someone_comments_on_") }}
        </label>
        <label for="exampleCheck3">
          <input
            type="checkbox"
            id="exampleCheck3"
            class="mr-4"
            v-model="form.join_fan_page"
          />
          {{ $t("Set.49b@someone_join_inte") }}
        </label>
        <label for="exampleCheck4">
          <input
            type="checkbox"
            id="exampleCheck4"
            class="mr-4"
            v-model="form.friend_add_you"
          />
          {{ $t("Set.ac1@someone_adds_or_conn") }}
        </label>
        <label for="exampleCheck5">
          <input
            type="checkbox"
            id="exampleCheck5"
            class="mr-4"
            v-model="form.private_message"
          />
          {{ $t("Set.6ba@private_message_sent") }}
        </label>
        <label for="exampleCheck6">
          <input
            type="checkbox"
            id="exampleCheck6"
            class="mr-4"
            v-model="form.jci_online_meeting"
          />
          {{ $t("Set.e04@someone_joins_your_o") }}
        </label>
        <label for="exampleCheck7">
          <input
            type="checkbox"
            id="exampleCheck7"
            class="mr-4"
            v-model="form.receive_diamond"
          />
          {{ $t("Set.5e6@receive_'diamond'") }}
        </label>
        <label for="exampleCheck8">
          <input
            type="checkbox"
            id="exampleCheck8"
            class="mr-4"
            v-model="form.up_wallet"
          />
          {{ $t("Set.fec@top_up_wallet") }}
        </label>
      </div>
    </div>
    <!-- btns   -->
    <div class="mt-5">
      <button
        type="submit"
        class="btn btn-primary mr-2"
        @click="notificationSet"
      >
        {{ $t("Set.ca8@submit") }}
      </button>
      <button type="reset" @click="reset" class="btn btn-danger">
        {{ $t("Set.cd7@cancel") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotificationSettings",
  mounted () {
    // this.getNotification();
  },
  components: {},
  data () {
    return {
      // 通知设置
      form: {
        email_notification: false,
        // sms_notification: false,
        friend_post: false,
        join_fan_page: false,
        friend_add_you: false,
        private_message: false,
        jci_online_meeting: false,
        receive_diamond: false,
        top_up_wallet: false,
      },
    };
  },
  methods: {
    reset () {
      this.form.email_notification = false;
      this.form.friend_post = false;
      this.form.join_fan_page = false;
      this.form.friend_add_you = false;
      this.form.private_message = false;
      this.form.jci_online_meeting = false;
      this.form.receive_diamond = false;
      this.form.top_up_wallet = false;
    },
    // 获取通知设置
    getNotification () {
      const data = {};
      this.$http.getNotification(data).then((res) => {
        if (res.status == 200) {
          this.form = this.transformationBoolean(res.data);
        }
      });
    },
    // 修改通知设置
    notificationSet () {
      const params = this.transformationNumber(this.form);
      const data = { ...params };
      this.$http.notificationSet(data).then((res) => {
        // console.log(res);
        if (res.status == 200) {
        }
      });
    },
    // 将数字转为boolean
    transformationBoolean (params) {
      let obj = {};
      Object.keys(params).forEach((v, i) => {
        if (v !== "id") {
          params[v] == 1
            ? (obj[v] = true)
            : params[v] == 0
              ? (obj[v] = false)
              : "";
        }
      });
      return obj;
    },
    // 将boolean转为数字
    transformationNumber (params) {
      let obj = {};
      Object.keys(params).forEach((v, i) => {
        if (v !== "id") {
          params[v] == true
            ? (obj[v] = 1)
            : params[v] == false
              ? (obj[v] = 0)
              : "";
        }
      });
      return obj;
    },
  },
};
</script>
