<template>
  <div class="PrivacySettings">
    <h4 class="mb-3">{{ $t("Set.90f@privacy_settings") }}</h4>
    <hr />
    <section class="contentBox">
      <!-- Account Privacy -->
      <div>
        <div class="title">{{ $t("Set.18d@account_privacy") }}</div>
        <label
          for="exampleCheckPrivateAccount"
          class="d-flex align-items-center"
        >
          <input
            type="checkbox"
            id="exampleCheckPrivateAccount"
            class="mr-4"
            v-model="form.account_privacy"
            @change="PrivacySet"
          />
          {{ $t("Set.b45@private_account") }}
        </label>
        <div class="desc"></div>
      </div>

      <hr />

      <!-- Photo Of You -->
      <!-- <div>
        <div class="title">Photo Of You</div>
        <div class="form-check mb-1">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadiosPhotoOfYou"
            id="exampleRadiosPhotoOfYou1"
            value="false"
            v-model="form.photo_of_you"
            @change="PrivacySet"
          />
          <label class="form-check-label" for="exampleRadiosPhotoOfYou1">
            Add Manually
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadiosPhotoOfYou"
            id="exampleRadiosPhotoOfYou2"
            value="true"
            v-model="form.photo_of_you"
            @change="PrivacySet"
          />
          <label class="form-check-label" for="exampleRadiosPhotoOfYou2">
            Add Automatically
          </label>
        </div>
        <div class="desc"></div>
      </div> 
      <hr />
      -->

      <!-- Your Profile -->
      <!-- <div>
        <div class="title">Your Profile</div>
        <div class="form-check mb-1">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadiosYourProfile"
            id="exampleRadiosYourProfile1"
            value="false"
            v-model="form.your_profile"
            @change="PrivacySet"
          />
          <label class="form-check-label" for="exampleRadiosYourProfile1">
            Public
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadiosYourProfile"
            id="exampleRadiosYourProfile2"
            value="true"
            v-model="form.your_profile"
            @change="PrivacySet"
          />
          <label class="form-check-label" for="exampleRadiosYourProfile2">
            Friend
          </label>
        </div>
        <div class="desc"></div>
      </div> -->

      <!-- <hr /> -->

      <!-- Login Notification -->
      <!-- <div>
        <div class="title">Login Notification</div>
        <div class="form-check mb-1">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadiosLoginNotification"
            id="exampleRadiosLoginNotification1"
            value="false"
            v-model="form.login_notification"
            @change="PrivacySet"
          />
          <label class="form-check-label" for="exampleRadiosLoginNotification1">
            Enable
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadiosLoginNotification"
            id="exampleRadiosLoginNotification2"
            value="true"
            v-model="form.login_notification"
            @change="PrivacySet"
          />
          <label class="form-check-label" for="exampleRadiosLoginNotification2">
            Disable
          </label>
        </div>
        <div class="desc"></div>
      </div>
      <hr />
       -->
      <!-- Chat -->
      <div>
        <div class="title">{{ $t("Set.016@chat") }}</div>
        <div class="form-check mb-1 p-0">
          <b-form-checkbox
            id="checkbox-caht-1"
            name="checkbox-chat-1"
            v-model="form.chat_groups"
            @change="PrivacySet"
          >
            {{ $t("Set.84c@do_not_accept_invite") }}
          </b-form-checkbox>
        </div>
        <div class="form-check p-0">
          <b-form-checkbox
            id="checkbox-chat-2"
            name="checkbox-chat-2"
            v-model="form.chat_unknown_members"
            @change="PrivacySet"
          >
            {{ $t("Set.7b7@do_not_accept_messag") }}
          </b-form-checkbox>
        </div>
        <div class="desc"></div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "PrivacySettings",
  mounted () { },
  components: {},
  data () {
    return {
      // 隐私设置
      form: {
        account_privacy: false,
        photo_of_you: false,
        your_profile: false,
        login_notification: false,
        chat_groups: false,
        chat_unknown_members: false,
      },
    };
  },
  methods: {
    // 获取隐私设置
    getPrivacy () {
      const data = {};
      this.$http.getPrivacy(data).then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          this.form = this.transformationBoolean(res.data);
        }
      });
    },
    // 修改隐私设置
    PrivacySet () {
      let params = this.transformationNumber(this.form);
      const data = {
        ...params,
      };
      // console.log(this.form);
      this.$http.PrivacySet(data).then((res) => {
        if (res.status == 200) {
          // console.log(res);
        }
      });
    },
    // 将数字转为boolean
    transformationBoolean (params) {
      let obj = {};
      Object.keys(params).forEach((v, i) => {
        if (v !== "id") {
          params[v] == 1
            ? (obj[v] = true)
            : params[v] == 0
              ? (obj[v] = false)
              : "";
        }
      });
      return obj;
    },
    // 将boolean转为数字
    transformationNumber (params) {
      let obj = {};
      Object.keys(params).forEach((v, i) => {
        if (v != "id") {
          if (params[v] == true || params[v] == "true") {
            obj[v] = 1;
          } else if (params[v] == false || params[v] == "false") {
            obj[v] = 0;
          }
        }
      });
      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
.PrivacySettings {
  .contentBox {
    .title {
      font-size: 20px;
      color: #333333;
      margin-bottom: 8px;
    }
    .desc {
      font-weight: 300;
      color: #666666;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}
</style>
