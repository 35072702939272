<template>
  <div>
    <h4>{{ $t("Set.e52@special_membership") }}</h4>
    <div v-if="specalStatus">
      <div v-if="applySpecial && applySpecial.id">
        <hr />
        <div class="row">
          <div class="col-4 title">{{ $t("Set.e52@special_membership") }}</div>
          <div v-if="applySpecial.role_type == 0" class="col-8">
            {{ $t("Set.2f2@merchant") }}
          </div>
          <div v-else class="col-8">{{ $t("Set.988@lecturer") }}</div>
        </div>
        <div class="row" v-if="applySpecial.role_type == 1">
          <div class="col-4 title">{{ $t("Set.f17@status") }}</div>
          <div v-if="applySpecial.type == 1" class="col-8">
            {{ $t("Set.801@proson") }}
          </div>
          <div v-else class="col-8">{{ $t("Set.e2a@company") }}</div>
        </div>
        <div class="row">
          <div class="col-4 title" v-if="applySpecial.company_name">
            {{ $t("Set.c72@company_name") }}
          </div>
          <div class="col-8">{{ applySpecial.company_name }}</div>
        </div>
        <div class="row">
          <div class="col-4 title">{{ $t("Set.12b@application_status") }}</div>
          <div
            v-if="applySpecial.status == 0"
            style="color: #999"
            class="col-4"
          >
            Under review...
          </div>
          <div
            v-else-if="applySpecial.status == 1"
            style="color: #49f0d3"
            class="col-4"
          >
            {{ $t("Set.6f7@approved") }}
          </div>
          <div
            v-else-if="applySpecial.status == 2"
            style="color: #ff9b8a"
            class="col-4"
          >
            {{ $t("Set.239@rejected") }}
          </div>
          <div
            class="col-4"
            v-if="applySpecial.status == 2 || applySpecial.status == 1"
          >
            <a
              href="javascript:;"
              style="color: #999; text-decoration: underline"
              @click="singleApply(applySpecial)"
              >Amend application</a
            >
          </div>
          <div class="col-4" v-if="applySpecial.status == 0">
            <a
              href="javascript:;"
              style="color: #999; text-decoration: underline"
              @click="delApply(applySpecial.id)"
              >Cancel application</a
            >
          </div>
        </div>
        <div class="row" v-if="applySpecial.status == 2">
          <div class="col-4 title">Reason for rejection</div>
          <div class="col-8">{{ applySpecial.remarks }}</div>
        </div>
      </div>
      <div v-if="applyBuiss && applyBuiss.id" class="mb-5">
        <hr />
        <div class="row">
          <div class="col-4 title">{{ $t("Set.e52@special_membership") }}</div>
          <div v-if="applyBuiss.role_type == 0" class="col-8">
            {{ $t("Set.2f2@merchant") }}
          </div>
          <div v-else class="col-8">{{ $t("Set.988@lecturer") }}</div>
        </div>
        <div class="row" v-if="applyBuiss.role_type == 1">
          <div class="col-4 title">{{ $t("Set.f17@status") }}</div>
          <div v-if="applyBuiss.type == 1" class="col-8">
            {{ $t("Set.801@proson") }}
          </div>
          <div v-else class="col-8">{{ $t("Set.e2a@company") }}</div>
        </div>
        <div class="row">
          <div class="col-4 title" v-if="applyBuiss.company_name">
            {{ $t("Set.c72@company_name") }}
          </div>
          <div class="col-8">{{ applyBuiss.company_name }}</div>
        </div>
        <div class="row">
          <div class="col-4 title">{{ $t("Set.12b@application_status") }}</div>
          <div v-if="applyBuiss.status == 0" style="color: #999" class="col-4">
            Under review...
          </div>
          <div
            v-else-if="applyBuiss.status == 1"
            style="color: #49f0d3"
            class="col-4"
          >
            {{ $t("Set.6f7@approved") }}
          </div>
          <div
            v-else-if="applyBuiss.status == 2"
            style="color: #ff9b8a"
            class="col-4"
          >
            {{ $t("Set.239@rejected") }}
          </div>
          <div
            class="col-4"
            v-if="applyBuiss.status == 2 || applyBuiss.status == 1"
          >
            <a
              href="javascript:;"
              style="color: #999; text-decoration: underline"
              @click="singleApply(applyBuiss)"
              >Amend application</a
            >
          </div>
          <div class="col-4" v-if="applyBuiss.status == 0">
            <a
              href="javascript:;"
              style="color: #999; text-decoration: underline"
              @click="delApply(applyBuiss.id)"
              >Cancel application</a
            >
          </div>
        </div>
        <div class="row" v-if="applyBuiss.status == 2">
          <div class="col-4 title">Reason for rejection</div>
          <div class="col-8">{{ applyBuiss.remarks }}</div>
        </div>
      </div>
      <div style="display: block" v-if="applyBtn" class="mb-2 mt-5">
        <button
          type="submit"
          @click="applyAgain"
          style="width: 120px"
          class="btn btn-submit"
        >
          {{ $t("Set.2d3@apply_again") }}
        </button>
      </div>
    </div>
    <div v-else>
      <hr />
      <div class="notKyc" v-if="kycStatus == 'notKyc'">
        <p style="color: #3f414d">Please complete KYC Authentication first</p>
        <div style="margin-top: 120px; margin-bottom: 20px">
          <button
            type="submit"
            class="btn btn-submit mr-2"
            @click="
              $emit('checkTab', 'kycTab'),
                ($refs.message.style.display = 'block')
            "
          >
            To Authenticate
          </button>
        </div>
      </div>
      <div class="kyc1" v-if="kycStatus == 'kyc1'">
        <form class="mt-4">
          <!-- First Name -->
          <div class="form-group">
            <label for="exampleInputFirstName">{{
              $t("Set.df3@first_name")
            }}</label>
            <input
              type="text"
              disabled
              class="form-control mb-0"
              id="exampleInputFirstName"
              placeholder="Chan"
              v-model="mykyc.first_name"
            />
          </div>
          <!-- Last Name -->
          <div class="form-group">
            <label for="exampleInputLastName">{{
              $t("Set.33b@last_name")
            }}</label>
            <input
              type="text"
              class="form-control mb-0"
              disabled
              id="exampleInputLastName"
              placeholder="Dai Man"
              v-model="mykyc.last_name"
            />
          </div>
          <!-- ID Card NO. -->
          <div class="form-group">
            <label for="exampleInputIDCardNO">{{
              $t("Set.9a5@id_card_no")
            }}</label>
            <input
              type="text"
              class="form-control mb-0"
              disabled
              id="exampleInputIDCardNO"
              placeholder="4255859xxxxxxxx"
              v-model="mykyc.id_card"
            />
          </div>
          <!-- Address -->
          <div class="form-group">
            <label for="exampleInputAddress">{{ $t("Set.deb@address") }}</label>
            <input
              type="text"
              class="form-control mb-0"
              disabled
              id="exampleInputAddress"
              :placeholder="$t('Set.deb@address')"
              v-model="mykyc.address"
            />
          </div>
          <!-- Photo of ID card -->
          <div class="form-group">
            <label>{{ $t("Set.e82@photo_of_id_card") }}</label>
            <div class="d-flex">
              <el-upload
                disabled
                class="avatar-uploader mr-3"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
              >
                <img :src="mykyc.photo_of_id_card" alt="" />
              </el-upload>
              <el-upload
                disabled
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
              >
                <img :src="mykyc.photo_of_id_card_back" alt="" />
              </el-upload>
            </div>
          </div>
          <!-- Address proof -->
          <div class="form-group">
            <label for="exampleInputAddress">{{
              $t("Set.f45@address_proof")
            }}</label>
            <el-upload
              class="avatar-uploader"
              disabled
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
            >
              <img :src="mykyc.address_proof" alt="" />
            </el-upload>
          </div>
        </form>
        <div class="mt-3 mb-5">
          <el-button
            type="primary"
            class="btn btn-submit mr-2"
            @click="kycStatus = 'kyc2'"
          >
            Next
          </el-button>
        </div>
      </div>
      <div class="kyc2" v-if="kycStatus == 'kyc2'">
        <form class="mt-4">
          <div class="form-group">
            <label for="Account">
              <!-- {{ $t("Set.035@account_no./iban") }} -->
              Account No./IBAN
            </label>
            <input
              type="text"
              disabled
              class="form-control mb-0"
              id="Account"
              v-model="bankList.account"
            />
          </div>
          <!-- First Name -->
          <div class="form-group">
            <label for="BankName">{{ $t("Set.dc0@bank_name") }}</label>
            <input
              type="text"
              disabled
              class="form-control mb-0"
              id="BankName"
              v-model="bankList.bank_name"
            />
          </div>
          <!--Country -->
          <div class="form-group">
            <label for="Country">{{ $t("Set.72f@country") }}</label>
            <select class="form-control" style="appearance:none;line-height:35px" disabled v-model="bankList.nation_id">
              <option v-for="v of countryList" :key="v.id" :value="v.id">
                {{ v.name }}
              </option>
            </select>
          </div>
          <!-- ID Card NO. -->
          <div class="form-group">
            <label for="ClearingCode">{{
              $t("Set.f7b@swift_clearing_cod")
            }}</label>
            <div class="row m-0">
              <select class="form-control col-4" style="appearance:none;line-height:35px" disabled>
                <option v-for="(v, i) in swiftList" :key="i" :value="v.id">
                  {{ v.name }}
                </option>
              </select>
              <input
                type="text"
                disabled
                class="form-control offset-1 col-7"
                id="ClearingCode"
                v-model="bankList.bank_clearing_code"
              />
            </div>
          </div>
          <!-- Address -->
          <div class="form-group">
            <label for="Address">{{ $t("Set.deb@address") }}</label>
            <input
              type="text"
              disabled
              class="form-control mb-0"
              id="Address"
              v-model="bankList.address"
            />
          </div>
          <!-- Photo of ID card -->
          <div class="form-group">
            <label>{{ $t("Set.9d6@monthly_statement") }}</label>
            <div>
              <el-upload
                class="avatar-uploader"
                disabled
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadStatement"
              >
                <img class="w-100" :src="bankList.monthly_statement" alt="" />
              </el-upload>
            </div>
          </div>
        </form>
        <div class="mt-3 mb-5">
          <button type="submit" class="btn btn-submit mr-2" @click="back()">
            Next
          </button>
          <button
            type="submit"
            class="btn btn-back"
            @click="kycStatus = 'kyc1'"
          >
            Back
          </button>
        </div>
      </div>
      <!-- form 表单 -->
      <div v-if="messageType" ref="message">
        <form class="mt-4">
          <!-- Special Membership -->
          <div class="form-group">
            <label for="Country">{{ $t("Set.e52@special_membership") }}</label>
            <select :disabled="specalList.id" class="form-control" v-model="specalList.role_type">
              <option v-for="(v, i) in membershipList" :key="i" :value="v.id">
                {{ v.name }}
              </option>
            </select>
          </div>
          <div class="form-group box-sizing" v-if="specalList.role_type == 0">
            <label class="w-100" for="Country">{{
              $t("Set.a8a@category")
            }}</label>
            <div class="d-flex justify-content-between box-sizing">
              <select
                class="form-control"
                v-model="specalList.product_classifies_top_id"
                @change="handel(specalList.product_classifies_top_id)"
              >
                <option value="" disabled selected style="display: none">
                  {{ $t("Set.a8a@category") }}
                </option>
                <option v-for="(v, i) in categoryList" :key="i" :value="v.id">
                  {{ v.title }}
                </option>
              </select>
              <select
                class="form-control ml-3"
                v-if="specalList.product_classifies_top_id"
                v-model="specalList.product_classifies_id"
              >
                <option v-for="(v, i) in handelList" :key="i" :value="v.id">
                  {{ v.title }}
                </option>
              </select>
            </div>
          </div>
          <!-- 根据身份判断 -->
          <div class="form-group" v-if="specalList.role_type == 1">
            <label for="Country">{{ $t("Set.f17@status") }}</label>
            <select class="form-control" v-model="specalList.type">
              <option v-for="(v, i) in statusList" :key="i" :value="v.id">
                {{ v.name }}
              </option>
            </select>
          </div>
          <div
            class="form-group"
            v-if="specalList.role_type == 1 && specalList.type == 1"
          >
            <label>{{ $t("Set.13d@qualification_certif") }}</label>
            <div class="d-flex">
              <el-upload
                class="avatar-uploader"
                action="no"
                :show-file-list="false"
                :http-request="certificate"
              >
                <img
                  v-if="specalList.qualification_certificate"
                  :src="specalList.qualification_certificate"
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/images/settings/camera.png"
                  alt=""
                />
              </el-upload>
            </div>
            <div class="mt-5 mb-3">
              <el-button
                type="primary"
                class="mr-2"
                @click="addOrEdit()"
              >
                {{ $t("Set.ca8@submit") }}
              </el-button>
              <el-button
                v-if="!specalList.id"
                type="danger"
                plain
                @click="resetList"
              >
                {{ $t("Set.cd7@cancel") }}
              </el-button>
            </div>
          </div>
          <!-- 判断申请身份如果身份是商人，申请表如下：：： -->
          <div v-else-if="specalList.role_type == 0 || specalList.type == 0">
            <!-- Company Name -->
            <div class="form-group">
              <label for="exampleInputCompanyName">{{
                $t("Set.c72@company_name")
              }}</label>
              <input
                type="text"
                class="form-control mb-0"
                v-model="specalList.company_name"
                id="exampleInputCompanyName"
                :placeholder="$t('Set.abc@web_designer_co_ltd')"
              />
            </div>
            <!-- Business ID No. -->
            <div class="form-group">
              <label for="exampleInputBusinessIDNo">{{
                $t("Set.973@business_id_no")
              }}</label>
              <input
                type="text"
                class="form-control mb-0"
                id="exampleInputBusinessIDNo"
                v-model="specalList.business_id"
                placeholder="9999999999999999"
              />
            </div>
            <!-- Business Address -->
            <div class="form-group">
              <label for="exampleInputBusinessAddress">{{
                $t("Set.876@business_address")
              }}</label>
              <input
                type="text"
                v-model="specalList.business_address"
                class="form-control mb-0"
                id="exampleInputBusinessAddress"
                :placeholder="$t('Set.cf0@united_states_of_ame')"
              />
            </div>
            <!-- Business license -->
            <div class="form-group">
              <label>{{ $t("Set.fa3@business_license") }}</label>
              <div class="d-flex">
                <el-upload
                  class="avatar-uploader"
                  action="no"
                  :show-file-list="false"
                  :http-request="uploadBusiness"
                >
                  <img
                    v-if="specalList.merchants_prove"
                    :src="specalList.merchants_prove"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../assets/images/settings/camera.png"
                    alt=""
                  />
                </el-upload>
              </div>
            </div>
            <div class="mt-5 mb-3">
              <el-button
                type="primary"
                class="mr-2"
                @click="addOrEdit"
              >
                Submit
              </el-button>
              <el-button type="danger" plain @click="backKyc2">
                Back
              </el-button>
            </div>
          </div>
        </form>
      </div>
      <!-- next商人或公司信息 -->
      <!-- <div ref="bank" class="bank">
        <form class="mt-4">
          <div class="form-group">
            <label for="exampleInputCompanyName">{{
              $t("Set.035@account_no_iban")
            }}</label>
            <input
              type="text"
              v-model="specalList.account"
              class="form-control mb-0"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputBusinessIDNo">{{
              $t("Set.dc0@bank_name")
            }}</label>
            <input
              type="text"
              v-model="specalList.bank_name"
              class="form-control mb-0"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputBusinessAddress">{{
              $t("Set.72f@country")
            }}</label>
            <select class="form-control" v-model="specalList.nation_id">
              <option v-for="(v, i) in countryList" :key="i" :value="v.id">
                {{ v.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="exampleInputBusinessAddress">{{
              $t("Set.f7b@swift_clearing_cod")
            }}</label>
            <div class="row m-0">
              <select class="form-control col-4">
                <option v-for="(v, i) in swiftList" :key="i" :value="v">
                  {{ v }}
                </option>
              </select>
              <input
                type="text"
                v-model="specalList.bank_clearing_code"
                class="form-control offset-1 col-7 mb-0"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputBusinessAddress">{{
              $t("Set.deb@address")
            }}</label>
            <input
              type="text"
              v-model="specalList.address"
              class="form-control mb-0"
            />
          </div>
          <div class="form-group">
            <label>{{ $t("Set.9d6@monthly_statement") }}</label>
            <div>
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadStatement"
              >
                <img
                  class="w-100"
                  v-if="specalList.monthly_statement"
                  :src="specalList.monthly_statement"
                  alt=""
                />
                <img
                  class="w-100"
                  v-else
                  src="../../assets/images/settings/camera.png"
                  alt=""
                />
              </el-upload>
            </div>
          </div>
          <div class="mt-5 mb-3">
            <button
              type="submit"
              class="btn btn-submit mr-2"
              @click="getKycstatus()"
            >
              Next
            </button>
            <button type="reset" class="btn btn-back" @click="back">
              {{ $t("Set.be6@back") }}
            </button>
          </div>
        </form>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      membershipList: [
        //role_type
        { id: 0, name: "Merchant" },
        { id: 1, name: "Lecturer" },
      ],
      statusList: [
        //type
        { id: 0, name: "Company" },
        { id: 1, name: "Personal" },
      ],
      kycStatus: "",
      specalStatus: false,
      messageType: false,
      countryList: [],
      swiftList: [
        { id: "1", name: "SWIFT Code" },
        { id: "2", name: "Clearing Code" },
      ],
      categoryList: [], //商家类型一级
      handelList: [], //商家类型二级
      membership: "Merchant", // 默认选中
      status: "Personal",
      specalList: {
        company_name: "", // 企业名称
        business_id: "", // 企业识别号
        business_address: "", // 营业地址
        merchants_prove: "", // 商人证明
        type: "", // 0公司 / 1个人
        role_type: "", // 0商人 1个人
        qualification_certificate: "", // 讲师资格证书
        product_classifies_top_id: "",
        product_classifies_id: "",
        // account: "", // 银行账户
        // bank_name: "", // 银行名称
        // nation_id: "", // 国家ID
        // bank_clearing_code: "", // 银行清算代码
        // address: "", // 地址
        // monthly_statement: "", //月结单
      },
      applyBtn: true,
      applySpecial: {},
      applyBuiss: {},
      bankList: {},
      mykyc: {},
    };
  },
  components: {},
  mounted() {
    // this.applyList();
    this.getAddress(2);
  },
  methods: {
    addOrEdit() {
      if (this.specalList.id) {
        this.editApply();
      } else {
        this.addApply();
      }
    },
    singleApply(item) {
      // console.log(item);
      delete item.status_text;
      delete item.created_at;
      delete item.apply_role;
      delete item.status;
      this.specalList = item;
      this.specalList.apply_id = item.id;
      this.specalList.status = 0;
      this.specalStatus = false;
      if (item.product_classifies_top_id) {
        this.handel(item.product_classifies_top_id);
      }
      // this.$refs.bank.style.display = "none";
    },
    delApply(id) {
      this.$confirm("Are you sure to cancel the application?", "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$http
          .delApply({
            apply_id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.applyList();
              this.kycStatus = "kyc1";
              this.specalList.role_type = "";
              this.specalList.type = "";
              this.messageType = false;
            }
          });
      });
    },
    applyAgain() {
      this.messageType = true;
      this.specalStatus = false;
      this.membership = "Merchant";
      this.status = "Personal";
      this.specalList.role_type = "";
      delete this.specalList.id;
      this.specalList.type = "";
      this.specalList.product_classifies_id = "";
      this.specalList.product_classifies_top_id = "";
      this.kycStatus = "";
      this.specalList.company_name = "";
      this.specalList.business_id = "";
      this.specalList.business_address = "";
      this.specalList.merchants_prove = "";

      // this.$nextTick(() => {
      //   this.$refs.bank.style.display = "none";
      // });
    },
    getAddress(level) {
      this.$http.getAddress({ level }).then((res) => {
        if (res.status == 200) {
          this.countryList = res.data;
          // console.log("国家", this.countryList);
        }
      });
    },
    uploadStatement(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data.toString());
          this.specalList.monthly_statement = res.data.toString();
        }
      });
    },
    back() {
      this.messageType = true;
      this.kycStatus = "";
      // this.$refs.bank.style.display = "none";
    },
    backKyc2() {
      this.kycStatus = "kyc2";
      this.messageType = false;
    },
    backKyc1() {
      this.kycStatus = "";
      this.messageType = true;
    },
    myKyc() {
      this.mykyc = {};
      this.$http.myKyc().then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.mykyc = res.data;
            this.mykyc.kyc_id = res.data.id;
            this.imgUrl = res.data.photo_of_id_card;
            this.backImgUrl = res.data.photo_of_id_card_back;
            this.addressUrl = res.data.address_proof;
          }
        }
      });
    },
    getBankFirst(id) {
      this.bankList = {};
      this.isEdit = true;
      this.$http.getBankFirst({ bank_id: id }).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.bankList = res.data;
          }

          // console.log(this.bankList);
        }
      });
    },
    async getKycstatus() {
      let res = await this.$http.kycList();
      if (res.status == 200) {
        this.messageType = false;
        // this.$refs.bank.style.display = "none";
        if (res.data?.kyc?.status == 1 && res.data?.kyc_bank?.status == 1) {
          await this.myKyc();
          await this.getBankFirst(res.data.kyc_bank.id);
          this.kycStatus = "kyc1";
        } else {
          this.kycStatus = "notKyc";
        }
      }
    },
    resetList() {
      this.membership = "Merchant";
    },
    // 商家证明
    uploadBusiness(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data.toString());
          this.specalList.merchants_prove = res.data.toString();
        }
      });
    },
    handel(id) {
      this.$http.getProductClassify({ parent_id: id }).then((res) => {
        if (res.status == 200) {
          this.handelList = res.data;
          // console.log("商品分类", res.data);
        }
      });
    },
    // 获取商家类型
    getProductClassify() {
      this.$http.getProductClassify().then((res) => {
        if (res.status == 200) {
          this.categoryList = res.data;
          // console.log("商家类型", res.data);
        }
      });
    },
    // 个人---讲师证明
    certificate(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data.toString());
          this.specalList.qualification_certificate = res.data.toString();
        }
      });
    },
    // ////////////////   特殊身份  ///////
    // 申请角色列表
    applyList() {
      this.$http.applyList().then((res) => {
        if (res.status == 200) {
          this.applySpecial = res.data.lecturer;
          this.applyBuiss = res.data.business;
          if (res.data.lecturer?.id && res.data.business?.id) {
            this.applyBtn = false;
          } else {
            this.applyBtn = true;
          }
          if (res.data.lecturer?.id || res.data.business?.id) {
            this.specalStatus = true;
          } else {
            this.specalStatus = false;
          }
        }
      });
    },
    // 编辑申请信息
    editApply() {
      const data = JSON.parse(JSON.stringify(this.specalList));
      this.$http.editApply(data).then((res) => {
        if (res.status == 200) {
          this.specalStatus = true;
          this.applyList();
          this.specalList = {};
          // this.$refs.bank.style.display = "none";
        }
      });
    },
    // 点击NEXT下一步
    // nextForm() {
    //   this.messageType = false;
    //   this.$refs.bank.style.display = "block";
    // },
    // 申请特殊身份
    addApply() {
      const data = JSON.parse(JSON.stringify(this.specalList));
      this.$http.addApply(data).then((res) => {
        if (res.status == 200) {
          this.specalStatus = true;
          this.applyList();
          this.specalList = {};
          // this.$refs.bank.style.display = "none";
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  min-width: 78px;
  height: 35px;
  border-radius: 5px;
}
.btn-back {
  background: #fff5f4;
  color: #ff9b8a;
}
.btn-submit {
  background: #50b5ff;
  color: #ffffff;
}
.bank {
  display: none;
}
.title {
  color: #333;
}
.avatar-uploader {
  width: 90px;
  height: 90px;
  overflow: hidden;
  img {
    width: 90px;
    height: 90px;
  }
}
</style>
