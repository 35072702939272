<template>
  <div>
    <h4>{{ $t("Set.0c1@account_membership") }}</h4>
    <hr />
    <div>
      <div class="row pb-5">
        <div class="col-sm-3 col-4 titleColor">{{ $t("Set.440@user_type") }}</div>
        <div class="col-sm-9 col-8">{{ $t("Set.10c@guest") }}</div>
      </div>
      <div class="mt-5 mb-3">
        <p>{{ $t("Set.e0a@select_an_account") }}</p>
        <select class="form-control applyType" v-model="type">
          <option value="0">{{ $t("Set.641@sponsor") }}</option>
          <option value="1">{{ $t("Set.a41@jci_staff") }}</option>
          <option value="2">{{ $t("Set.63f@partner") }}</option>
        </select>
        <div class="my-2">
          <textarea v-model="remarks" class="form-control"></textarea>
        </div>
        <button type="submit" class="btn btn1" @click="addAccountMembership">
          {{ $t("Set.ca8@submit") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      type: 0,
      remarks: ""
    };
  },
  mounted () { },
  methods: {
    addAccountMembership () {
      this.$http.addAccountMembership({
        type: this.type,
        remarks: this.remarks
      }).then(res => {
        if (res.status == 200) {

        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.btn1 {
  color: #fff;
  background-color: #50b5ff;
  border: 3px solid #50b5ff;
}
textarea {
  width: 75%;
  border: 1px solid #999;
  border-radius: 5px !important;
  height: 70px !important;
}
.applyType {
  width: 130px !important;
  display: inline-block;
  height: 39px !important;
  border-radius: 5px !important;
  margin-right: 10px;
}
</style>
