import { render, staticRenderFns } from "./Kyc1.vue?vue&type=template&id=081c0a3e&scoped=true&"
import script from "./Kyc1.vue?vue&type=script&lang=js&"
export * from "./Kyc1.vue?vue&type=script&lang=js&"
import style0 from "./Kyc1.vue?vue&type=style&index=0&id=081c0a3e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081c0a3e",
  null
  
)

export default component.exports