<template>
  <div v-loading="loading">
    <form class="mt-4">
      <!-- First Name -->
      <div class="form-group">
        <label for="exampleInputFirstName">{{
          $t("Set.df3@first_name")
        }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="exampleInputFirstName"
          placeholder="Chan"
          v-model="mykyc.first_name"
        />
      </div>
      <!-- Last Name -->
      <div class="form-group">
        <label for="exampleInputLastName">{{ $t("Set.33b@last_name") }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="exampleInputLastName"
          placeholder="Dai Man"
          v-model="mykyc.last_name"
        />
      </div>
      <!-- ID Card NO. -->
      <div class="form-group">
        <label for="exampleInputIDCardNO">{{ $t("Set.9a5@id_card_no") }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="exampleInputIDCardNO"
          placeholder="4255859xxxxxxxx"
          v-model="mykyc.id_card"
        />
      </div>
      <!-- Address -->
      <div class="form-group">
        <label for="exampleInputAddress">{{ $t("Set.deb@address") }}</label>
        <input
          type="text"
          class="form-control mb-0"
          id="exampleInputAddress"
          :placeholder="$t('Set.deb@address')"
          v-model="mykyc.address"
        />
      </div>
      <!-- Photo of ID card -->
      <div class="form-group">
        <label>{{ $t("Set.e82@photo_of_id_card") }}</label>
        <div class="d-flex">
          <section class="mr-5">
            <el-upload
              class="avatar-uploader mr-3"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :http-request="uploadIMG"
            >
              <img v-if="imgUrl" :src="imgUrl" alt="" />
              <img v-else src="../../assets/images/settings/camera.png" alt="" />
            </el-upload>
            <p>Front of ID card</p>
          </section>
          <section>
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :http-request="uploadIMGback"
            >
              <img v-if="backImgUrl" :src="backImgUrl" alt="" />
              <img v-else src="../../assets/images/settings/camera.png" alt="" />
            </el-upload>
            <p>Back of the ID card</p>
          </section>
        </div>
      </div>
      <!-- Address proof -->
      <div class="form-group">
        <label for="exampleInputAddress">{{
          $t("Set.f45@address_proof")
        }}</label>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="uploadIMGaddress"
        >
          <img v-if="addressUrl" :src="addressUrl" alt="" />
          <img v-else src="../../assets/images/settings/camera.png" alt="" />
        </el-upload>
      </div>
    </form>
    <div class="mt-5 mb-3">
      <span v-show="mykyc.id">
        <button
          v-if="
            mykyc.status == '1' || mykyc.status == '2'
          "
          type="submit"
          class="btn btn-primary mr-2"
          @click="kyc()"
        >
          Amend
        </button>
        <button
          v-else
          type="submit"
          class="btn btn-primary mr-2"
          @click="$emit('cancelApply', mykyc.id)"
        >
          Cancel application
        </button>
      </span>
      <button
        v-show="!mykyc.id"
        type="submit"
        @click="kyc()"
        class="btn btn-primary mr-2"
      >
        {{ $t("Set.ca8@submit") }}
      </button>
      <button type="submit" class="btn btn-danger" @click="back(), clearBack()">
        {{ $t("Set.be6@back") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      mykyc: {
        first_name: "",
        last_name: "",
        kyc_id: "",
        id_card: "",
        address: "",
        photo_of_id_card: "",
        photo_of_id_card_back: "",
        address_proof: "",
      },
      imgUrl: "",
      backImgUrl: "",
      addressUrl: "",
      loading:false
    };
  },
  methods: {
    clearBack() {
      this.mykyc.first_name = "";
      this.mykyc.last_name = "";
      this.mykyc.kyc_id = "";
      this.mykyc.id_card = "";
      this.mykyc.address = "";
      this.mykyc.photo_of_id_card = "";
      this.mykyc.photo_of_id_card_back = "";
      this.mykyc.address_proof = "";
      this.imgUrl = "";
      this.backImgUrl = "";
      this.addressUrl = "";
    },
    // 返回上一步
    back() {
      this.$emit("previous");
    },
    // ///////// ///kyc //////////
    // 身份证正面
    uploadIMG(params) {
      this.loading=true
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data.toString());
          this.mykyc.photo_of_id_card = res.data.toString();
          this.imgUrl = res.data.toString();
        }
        this.loading=false
      });
    },
    // 身份证反面
    uploadIMGback(params) {
      this.loading = true
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          this.mykyc.photo_of_id_card_back = res.data.toString();
          this.backImgUrl = res.data.toString();
          this.loading = false
        }
      });
    },
    // 地址证明
    uploadIMGaddress(params) {
      this.loading = true
      var formData = new FormData();
      formData.append("file", params.file);
      // console.log(formData.get("file"));
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          this.mykyc.address_proof = res.data.toString();
          this.addressUrl = res.data.toString();
          this.loading = false
        }
      });
    },
    // 获取kyc身份信息
    myKyc() {
      this.mykyc={};
      this.$http.myKyc().then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.mykyc = res.data;
            this.mykyc.kyc_id = res.data.id;
            this.imgUrl = res.data.photo_of_id_card;
            this.backImgUrl = res.data.photo_of_id_card_back;
            this.addressUrl = res.data.address_proof;
          }
        }
      });
    },
    // 新增Kyc身份
    addKyc() {
      const data = JSON.parse(JSON.stringify(this.mykyc));
      this.$http.addKyc(data).then((res) => {
        if (res.status == 200) {
          // consoe.log(res);
          this.back();
          this.clearBack();
        }
      });
    },
    // 编辑Kyc信息
    editKyc() {
      const data = JSON.parse(JSON.stringify(this.mykyc));
      this.$http
        .editKyc({
          kyc_id: this.mykyc.kyc_id,
          first_name: this.mykyc.first_name,
          last_name: this.mykyc.last_name,
          id_card: this.mykyc.id_card,
          address: this.mykyc.address,
          photo_of_id_card: this.mykyc.photo_of_id_card,
          photo_of_id_card_back: this.mykyc.photo_of_id_card_back,
          address_proof: this.mykyc.address_proof,
        })
        .then((res) => {
          if (res.status == 200) {
            this.back();
            this.clearBack();
          }
        });
    },
    // 判断为新增kyc  or  编辑
    kyc() {
      if (!this.mykyc.kyc_id) {
        this.addKyc();
      } else {
        this.editKyc();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-uploader {
  width: 90px;
  height: 90px;
  overflow: hidden;
  img {
    width: 90px;
    height: 90px;
  }
}

.form-group {
  label::before {
    content:'*';
    color:red;
    position:absolute;
    left:-2px;
  }
}
</style>
